import { all } from "redux-saga/effects";
import actionLogin from "./modules/login/sagas";
import actionSearch from "./modules/search/sagas";
import actionAdmin from "./modules/admin/sagas";
import actionLPS from "./modules/lps-form/sagas";
import actionNewEntity from "./modules/add-new-entity/sagas";
import actionLeadgen from "./modules/leadgen/sagas";
import actionSuperAdmin from "./modules/super-admin/sagas";
import actionLeads from "./modules/leads/sagas";
import actionPDF from "./modules/importPDF/sagas";
// import actionLeadgenHeadMenu from "./modules/leadgen-head-menu/sagas";
// import actionQueryObjects from "./modules/query-objects/sagas";

export default function* rootSaga() {
  yield all([
    actionLogin(),
    actionSearch(),
    actionAdmin(),
    actionNewEntity(),
    actionLPS(),
    actionLeadgen(),
    actionSuperAdmin(),
    actionLeads(),
    actionPDF(),

    // actionLeadgenHeadMenu(),
    // actionQueryObjects(),
  ]);
}
