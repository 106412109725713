import { combineReducers } from "redux";
import {
  GET_OBJECT_BY_ID_ERROR,
  GET_OBJECT_BY_ID_SUCCESS,
  GET_OBJECTS_BY_OWNER_ERROR,
  GET_OBJECTS_BY_OWNER_SUCCESS,
  SHOW_OBJECT_ADDRESS_ON_MAP,
  SET_OBJECT_DELETE,
  DELETE_OBJECT_SUCCESS,
  UPDATE_OBJECT_SUCCESS,
  RESET_OBJECT_ADDRESS_ON_MAP,
} from "../../../common/constants/action-constants";

const initialState = {
  isDeletingObject: false,
};

const receiveObjects = (state = [], action: any) => {
  switch (action.type) {
    case GET_OBJECTS_BY_OWNER_SUCCESS:
      return action.objects;
    case SET_OBJECT_DELETE:
      return {};
    default:
      return state;
  }
};

const currentObject = (state = {}, action: any) => {
  switch (action.type) {
    case UPDATE_OBJECT_SUCCESS:
    case GET_OBJECT_BY_ID_SUCCESS:
      return action.object;
    case SET_OBJECT_DELETE:
      return {};

    default:
      return state;
  }
};

const objectsError = (state = {}, action: any) => {
  switch (action.type) {
    case GET_OBJECTS_BY_OWNER_ERROR:
    case GET_OBJECT_BY_ID_ERROR:
      return action.error;
    default:
      return state;
  }
};

const objectAddressForMap = (state = {}, action: any) => {
  switch (action.type) {
    case RESET_OBJECT_ADDRESS_ON_MAP:
    case SHOW_OBJECT_ADDRESS_ON_MAP:
      return action.address;
    default:
      return state;
  }
};

const responseRemoveObject = (state = initialState, action: any) => {
  switch (action.type) {
    case DELETE_OBJECT_SUCCESS:
      return {
        isDeletingObject: true,
      };
    case SET_OBJECT_DELETE:
      return {
        isDeletingObject: false,
      };
    default:
      return state;
  }
};

const objects = combineReducers({
  receiveObjects,
  currentObject,
  responseRemoveObject,
  address: objectAddressForMap,
  error: objectsError,
});

export default objects;
