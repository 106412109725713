import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectLeadgen } from "../../selectors";
import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styles from "../../Leadgen.styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import Item from "../common/Item";
import { formatBirthDate } from "../../../../common/formats/date-fromatter";
import eventEmitter, {
  types,
} from "../../../../common/components/helpers/eventEmitter";

interface stakeholdersProps {
  entityId: string;
  owners?: any;
  entityObjectsListIds?: any;
  entityObjectsIds?: any;
}

/** object owners **/

const Stakeholders = (props: stakeholdersProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { entityId, owners } = props;
  const [stakeholders, setStakeholders] = useState([]);

  useEffect(() => {
    owners && setStakeholders(owners);
  }, [owners]);

  return (
    <Box>
      {stakeholders.length > 0 ? (
        stakeholders.map((owner: any, index) => {
          return (
            <Box key={index} sx={styles.ownersHolder}>
              <Box>
                <Box sx={styles.editButtonGroup}>
                  <Box sx={styles.firstIconHolder}>
                    <IconButton
                      onClick={() =>
                        eventEmitter.emit(
                          types.openScheduleCalendarModal,
                          owner.id
                        )
                      }
                    >
                      <CalendarMonthIcon fontSize={"small"} />
                    </IconButton>
                    <AccountCircleIcon
                      fontSize={"small"}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography sx={styles.stakeholderName} variant="h3">
                      {owner.name ? owner.name : owner.company_name}
                    </Typography>
                  </Box>
                  <Box sx={styles.secondIconHolder}>
                    <IconButton>
                      <LinkedInIcon
                        fontSize={"small"}
                        sx={styles.linkedInIcon}
                      />
                    </IconButton>
                    <IconButton>
                      <FacebookIcon
                        fontSize={"small"}
                        sx={styles.facebookIcon}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <Item title={"Age:"} text={owner.age ? owner.age : "n/a"} />
                    <Item
                      title={"D.o.B:"}
                      text={
                        owner.birth_date
                          ? formatBirthDate(owner.birth_date)
                          : "n/a"
                      }
                    />
                    <Item
                      title={"Address:"}
                      text={
                        owner.addresses.length > 0
                          ? owner.addresses[0].address
                          : "n/a"
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <Item
                      title={"Phone number:"}
                      text={
                        owner.phone_numbers.length > 0
                          ? owner.phone_numbers[0].number
                          : "n/a"
                      }
                    />
                    <Item
                      title={"Email:"}
                      text={
                        owner.emails.length > 0 ? owner.emails[0].email : "n/a"
                      }
                    />
                  </Grid>
                </Grid>
                {/*<Item title={'Age:'} text={owner.age ? owner.age : 'n/a'} />*/}
                {/*<Item*/}
                {/*  title={'D.o.B:'}*/}
                {/*  text={*/}
                {/*    owner.birth_date ? formatBirthDate(owner.birth_date) : 'n/a'*/}
                {/*  }*/}
                {/*/>*/}
                {/*<Item*/}
                {/*  title={'Address:'}*/}
                {/*  text={*/}
                {/*    owner.addresses.length > 0*/}
                {/*      ? owner.addresses[0].address*/}
                {/*      : 'n/a'*/}
                {/*  }*/}
                {/*/>*/}
              </Box>
            </Box>
          );
        })
      ) : (
        <Typography variant="body2" component="div">
          There are no other stakeholders except chosen entity
        </Typography>
      )}
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { owners } = selectLeadgen(state);
  return owners;
}

export default connect(mapStateToProps)(Stakeholders);
