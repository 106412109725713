import styles from "../LPSForm.styles";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import StyledTextField from "../../../common/components/styled-components/StyledTextField";
import { Add } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const objectConditionOptions = [
  {
    value: "include",
    label: "include",
  },
  {
    value: "exclude",
    label: "exclude",
  },
];

const objectTypeOptions = [
  {
    value: "woonfunctie",
    label: "woonfunctie",
  },
  {
    value: "bijeenkomstfunctie",
    label: "bijeenkomstfunctie",
  },
  {
    value: "celfunctie",
    label: "celfunctie",
  },
  {
    value: "gezondheidszorgfunctie",
    label: "gezondheidszorgfunctie",
  },
  {
    value: "industriefunctie",
    label: "industriefunctie",
  },
  {
    value: "kantoorfunctie",
    label: "kantoorfunctie",
  },
  {
    value: "logiesfunctie",
    label: "logiesfunctie",
  },
  {
    value: "onderwijsfunctie",
    label: "onderwijsfunctie",
  },
  {
    value: "sportfunctie",
    label: "sportfunctie",
  },
  {
    value: "winkelfunctie",
    label: "winkelfunctie",
  },
  {
    value: "overige gebruiksfunctie",
    label: "overige gebruiksfunctie",
  },
];

const ObjectTypesBlock = ({ onChange, objectTypesRules, onAdd, onDelete }) => {
  return (
    <>
      <AppBar position="relative" sx={styles.dataBoxSecond}>
        <Toolbar sx={styles.toolBarSecond}>
          <Typography component="div" sx={styles.headerContentSecond}>
            Object type condition
          </Typography>
          <Button
            disabled={objectTypesRules.length >= 11}
            onClick={() =>
              onAdd("lpsc_object_types_rules", "lpsc_object_types_rules")
            }
          >
            <Add sx={{ color: "white" }} />
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={styles.fieldHolderSecond}>
        {objectTypesRules.length > 0 &&
          objectTypesRules.map(({ variable, condition, unit_size }, index) => (
            <Box sx={styles.fieldBlockSecond} key={index}>
              <StyledTextField
                fullWidth
                select
                label="Variable"
                sx={styles.inputTextField}
                slotProps={{
                  inputLabel: {
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "1rem",
                      },
                    },
                  },
                }}
                margin="dense"
                defaultValue=""
                onChange={(e) =>
                  onChange("lpsc_object_types_rules", "variable", e, index)
                }
                value={variable || ""}
              >
                {objectConditionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                fullWidth
                select
                label="Condition"
                sx={styles.inputTextField}
                slotProps={{
                  inputLabel: {
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "1rem",
                      },
                    },
                  },
                }}
                margin="dense"
                defaultValue=""
                onChange={(e) =>
                  onChange("lpsc_object_types_rules", "condition", e, index)
                }
                value={condition || ""}
              >
                {objectTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                fullWidth
                label="Unit size m2"
                sx={styles.inputTextField}
                slotProps={{
                  inputLabel: {
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "1rem",
                      },
                    },
                  },
                }}
                margin="dense"
                type="number"
                onChange={(e) =>
                  onChange("lpsc_object_types_rules", "unit_size", e, index)
                }
                value={unit_size || ""}
              />
              <IconButton
                sx={{
                  color: "#B4CEE5",
                  fontSize: {
                    xs: "medium",
                    sm: "medium",
                    md: "24px",
                  },
                }}
                onClick={() =>
                  onDelete(
                    "lpsc_object_types_rules",
                    "lpsc_object_types_rules",
                    index
                  )
                }
                aria-label="Delete"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default ObjectTypesBlock;
