import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../EntityEditor.styles";
import { entityTypes } from "../../../constants/field-constants";
import StyledTextField from "../../styled-components/StyledTextField";
import React from "react";
import EntityCompanyBlock from "./EntityCompanyBlock";

const genderOptions = [
  {
    label: "Mr.",
    value: "M",
  },
  {
    label: "Ms.",
    value: "V",
  },
  {
    label: "M/V",
    value: "",
  },
];

const blackListedOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
  {
    label: "n/a",
    value: "",
  },
];

const isDeadOptions = [
  {
    label: "Yes",
    value: "false",
  },
  {
    label: "No",
    value: "true",
  },
  {
    label: "n/a",
    value: "",
  },
];

const isResidentOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
  {
    label: "n/a",
    value: "",
  },
];

const GeneralFieldsBlock = ({
  entityType,
  onChange,
  entityFields,
  entityCompany,
  errors,
  onChangeCompany,
}) => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          General fields
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {entityType === entityTypes.PERSON ? (
          <>
            <Box sx={styles.fieldHolder}>
              <Box sx={styles.fieldBlock}>
                <StyledTextField
                  fullWidth
                  label="First name"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "first_name", e, -1)
                  }
                  value={entityFields?.first_name}
                />
                <StyledTextField
                  fullWidth
                  label="Prefix"
                  margin="dense"
                  onChange={(e) => onChange("generalFields", "prefix", e, -1)}
                  value={entityFields?.prefix}
                />
                <StyledTextField
                  fullWidth
                  required
                  label="Last name"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "last_name", e, -1)
                  }
                  value={entityFields?.last_name}
                  error={!errors.isFilledLastName}
                />
                <StyledTextField
                  fullWidth
                  label="Initials"
                  margin="dense"
                  onChange={(e) => onChange("generalFields", "initials", e, -1)}
                  value={entityFields?.initials}
                />
                <StyledTextField
                  fullWidth
                  label="All names"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "all_names", e, -1)
                  }
                  value={entityFields?.all_names}
                />
                <StyledTextField
                  fullWidth
                  label="Nickname"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "nick_name", e, -1)
                  }
                  value={entityFields?.nick_name}
                />
                <StyledTextField
                  fullWidth
                  select
                  label="Gender"
                  margin="dense"
                  defaultValue=""
                  value={entityFields?.gender || ""}
                  onChange={(e) => onChange("generalFields", "gender", e, -1)}
                >
                  {genderOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  fullWidth
                  select
                  label="Blacklisted"
                  margin="dense"
                  defaultValue=""
                  value={entityFields?.blacklisted || ""}
                  onChange={(e) =>
                    onChange("generalFields", "blacklisted", e, -1)
                  }
                >
                  {blackListedOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  fullWidth
                  select
                  label="NL resident"
                  margin="dense"
                  defaultValue=""
                  value={entityFields?.is_resident || ""}
                  onChange={(e) =>
                    onChange("generalFields", "is_resident", e, -1)
                  }
                >
                  {isResidentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Box>
              <Box sx={styles.fieldBlock}>
                <StyledTextField
                  fullWidth
                  label="D.o.B"
                  margin="dense"
                  type="date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={(e) =>
                    onChange("generalFields", "birth_date", e, -1)
                  }
                  value={entityFields?.birth_date}
                />
                <StyledTextField
                  fullWidth
                  label="Y.o.B"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "birth_year", e, -1)
                  }
                  value={entityFields?.birth_year}
                />

                <StyledTextField
                  fullWidth
                  label="P.o.B"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "birth_place", e, -1)
                  }
                  value={entityFields?.birth_place}
                />
                <StyledTextField
                  fullWidth
                  select
                  label="Alive"
                  margin="dense"
                  defaultValue=""
                  value={entityFields?.is_dead || ""}
                  onChange={(e) => onChange("generalFields", "is_dead", e, -1)}
                >
                  {isDeadOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  fullWidth
                  label="K.P.N."
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "kadaster_personal_number", e, -1)
                  }
                  value={entityFields?.kadaster_personal_number}
                />
                <StyledTextField
                  fullWidth
                  label="Kad. Rechthebbende"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "kadaster_objects_amount", e, -1)
                  }
                  value={entityFields?.kadaster_objects_amount}
                />
                <StyledTextField
                  fullWidth
                  label="Kad. link"
                  margin="dense"
                  onChange={(e) =>
                    onChange("generalFields", "kadaster_url", e, -1)
                  }
                  value={entityFields?.kadaster_url}
                />
                <StyledTextField
                  fullWidth
                  label="Link"
                  margin="dense"
                  onChange={(e) => onChange("generalFields", "url", e, -1)}
                  value={entityFields?.url}
                />
              </Box>
            </Box>
            <EntityCompanyBlock
              onChange={onChangeCompany}
              entityCompany={entityCompany}
            />
          </>
        ) : (
          <Box sx={styles.fieldHolder}>
            <Box sx={styles.fieldBlock}>
              <StyledTextField
                fullWidth
                label="Company name"
                margin="dense"
                onChange={(e) =>
                  onChange("generalFields", "company_name", e, -1)
                }
                value={entityFields?.company_name}
                error={!errors.isFilledCompanyName}
              />
              <StyledTextField
                fullWidth
                label="KVK number"
                margin="dense"
                onChange={(e) =>
                  onChange("generalFields", "chamber_commerce_number", e, -1)
                }
                value={entityFields?.chamber_commerce_number}
              />
              <StyledTextField
                fullWidth
                label="KVK link"
                margin="dense"
                onChange={(e) =>
                  onChange("generalFields", "chamber_commerce_url", e, -1)
                }
                value={entityFields?.chamber_commerce_url}
              />
              <StyledTextField
                fullWidth
                select
                label="Blacklisted"
                margin="dense"
                defaultValue=""
                value={entityFields?.blacklisted || ""}
                onChange={(e) =>
                  onChange("generalFields", "blacklisted", e, -1)
                }
              >
                {blackListedOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                fullWidth
                select
                label="NL resident"
                margin="dense"
                defaultValue=""
                value={entityFields?.is_resident || ""}
                onChange={(e) =>
                  onChange("generalFields", "is_resident", e, -1)
                }
              >
                {isResidentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>
            <Box sx={styles.fieldBlock}>
              <StyledTextField
                fullWidth
                label="K.P.N."
                margin="dense"
                onChange={(e) =>
                  onChange("generalFields", "kadaster_personal_number", e, -1)
                }
                value={entityFields?.kadaster_personal_number}
              />
              <StyledTextField
                fullWidth
                label="Kad. Rechthebbende"
                margin="dense"
                onChange={(e) =>
                  onChange("generalFields", "kadaster_objects_amount", e, -1)
                }
                value={entityFields?.kadaster_objects_amount}
              />
              <StyledTextField
                fullWidth
                label="Kad. link"
                margin="dense"
                onChange={(e) =>
                  onChange("generalFields", "kadaster_url", e, -1)
                }
                value={entityFields?.kadaster_url}
              />
              <StyledTextField
                fullWidth
                label="Link"
                margin="dense"
                onChange={(e) => onChange("generalFields", "url", e, -1)}
                value={entityFields?.url}
              />
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default GeneralFieldsBlock;
