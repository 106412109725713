import { call, put, select, takeLatest } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import AlertService from "../../../common/services/notification.service";
import {
  checkBestLeadError,
  checkBestLeadSuccess,
  // receiveDocsError,
  // receiveDocsSuccess,
  receiveLeadsError,
  receiveLeadsSuccess,
} from "../actions/";
import { setLPSItem } from "../../lps-form/actions";
import {
  CHECK_BEST_LEAD_REQUEST,
  GO_TO_NEXT_LEAD,
  NEXT_LPS_LEAD,
  PREV_LPS_LEAD,
  RECEIVE_LEADS_DATA_REQUEST,
  RECEIVE_LEADS_REQUEST,
  // RECEIVE_DRIVE_DOCS_REQUEST,
} from "../../../common/constants/action-constants";

// function* getDriveList() {
//   const state = yield select();
//   const { setLeadId } = state.entity;
//   const { objects } = state.leadgen;
//   const { receiveObjects } = objects;
//   let folderData;
//   try {
//     if (setLeadId) {
//       folderData = yield call(ApiService.post, "/leads/docs", {
//         leadId: setLeadId,
//       });
//     } else {
//       folderData = yield call(ApiService.post, "/leads/docs", {
//         objectId: receiveObjects[0].object_id,
//       });
//     }
//     yield put(receiveDocsSuccess(folderData));
//   } catch (error) {
//     yield put(receiveDocsError(error));
//   }
// }

// function* getLeadsData(action) {
//   try {
//     const leads = yield call(ApiService.post, "/leads", {
//       responsible_person_type: action.params.responsible_person_type,
//       id: action.params.id,
//     });
//     if (leads) {
//       // yield put(receiveLeadsSuccess(leads));
//     } else {
//       AlertService.error("ACCESS IS DENIED");
//     }
//   } catch (error) {
//     // yield put(receiveLeadsError(error));
//   }
// }

function* getLeadsData(action) {
  try {
    const leads = yield call(ApiService.post, "/leads", {
      responsible_person_type: action.data.responsible_person_type,
      id: action.data.id,
    });
    if (leads) {
      yield put(receiveLeadsSuccess(leads));
    } else {
      AlertService.error("ACCESS IS DENIED");
    }
  } catch (error) {
    yield put(receiveLeadsError(error));
  }
}

function* getLead(leadId, filter, email) {
  try {
    let bestLead = yield call(ApiService.post, "/leads/check/free_lead", {
      leadID: leadId,
      email,
      filter,
    });

    return bestLead;
  } catch (error) {
    yield put(checkBestLeadError(error));
    return null;
  }
}

function* receiveLPSResultInBackground(action) {
  const state = yield select();
  const { freeLead } = state.leads;
  const { indexLPSItem } = state.search.lps;
  const { leadID } = freeLead.bestLead;
  if (leadID) {
    yield put(setLPSItem(indexLPSItem));
    action.navigate(`/leadgen/entity/${leadID}`);
  }
}

function isLeadData(leadData) {
  return !!(leadData && leadData.data && leadData.data.leadIsFree);
}

function* showBestLead(
  { initIndex: initIndexFromParam, directionOffset },
  action
) {
  try {
    const state = yield select();
    // const navigate = action.navigate;
    const { assigned, scheduled, isSkipped, selectedUserIds, findLeadFlag } =
      state.leads;

    const { processing } = state.lps;
    const { indexLPSItem } = processing;
    const lpcList = processing.result.lpc;

    const initIndex =
      initIndexFromParam !== null && initIndexFromParam !== undefined
        ? initIndexFromParam
        : indexLPSItem + directionOffset;

    const filter = { assigned, scheduled, isSkipped, selectedUserIds };
    let leadData = null;

    let leadIdIndex;
    for (
      leadIdIndex = initIndex;
      initIndex >= 0 && initIndex < lpcList.length;
      leadIdIndex += directionOffset
    ) {
      const leadInfo = lpcList[leadIdIndex];
      if (!leadInfo) {
        AlertService.info("LEADS ARE OVER");
        break;
      }

      const leadID = leadInfo.id;

      if (!leadID) {
        AlertService.info("LEADS ARE OVER");
        break;
      }
      leadData = yield getLead(leadID, filter, state.login.receive.email);
      if (findLeadFlag && isLeadData(leadData)) {
        yield put(checkBestLeadSuccess(leadData, leadIdIndex));
        yield put(setLPSItem(leadIdIndex));
        break;
      } else if (isLeadData(leadData)) {
        yield put(checkBestLeadSuccess(leadData, leadIdIndex));
        yield put(setLPSItem(leadIdIndex));
        // @ts-ignore
        action.navigate(`/leadgen/entity/${leadData.data.leadID}`);
        break;
      }
    }

    return null;
  } catch (e) {
    console.log("error");
  }
}

const FORWARD_DIRECTION = 1;
const BACKWARD_DIRECTION = -1;

export default function* actionLeads() {
  yield takeLatest(RECEIVE_LEADS_DATA_REQUEST, getLeadsData);
  yield takeLatest(RECEIVE_LEADS_REQUEST, getLeadsData);
  yield takeLatest(CHECK_BEST_LEAD_REQUEST, showBestLead, {
    initIndex: 0,
    directionOffset: FORWARD_DIRECTION,
  });
  // @ts-ignore
  yield takeLatest(NEXT_LPS_LEAD, showBestLead, {
    directionOffset: FORWARD_DIRECTION,
  });
  // @ts-ignore
  yield takeLatest(PREV_LPS_LEAD, showBestLead, {
    directionOffset: BACKWARD_DIRECTION,
  });
  // yield takeLatest(RECEIVE_DRIVE_DOCS_REQUEST, getDriveList);
  yield takeLatest(GO_TO_NEXT_LEAD, receiveLPSResultInBackground);
}
