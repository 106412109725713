const styles = {
  toolBar: {
    minHeight: {
      xs: "36px",
      sm: "40px",
      md: "50px",
      lg: "64px",
    },
  },
  toolBarSecond: {
    minHeight: {
      xs: "26px",
      sm: "35px",
      md: "45px",
      lg: "45px",
    },
  },
  dataBoxSecond: {
    // flexGrow: 1,
    height: {
      xs: "30px",
      sm: "40px",
      md: "40px",
      lg: "40px",
    },
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
    width: "93%",
    margin: " 0 auto",
  },
  dataBox: {
    flexGrow: 1,
    height: {
      xs: "40px",
      sm: "40px",
      md: "50px",
      lg: "60px",
    },
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
  },
  headerContent: {
    flexGrow: 1,
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "18px",
    },
    color: "#FFF",
  },
  headerContentSecond: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "14px",
    },
    color: "#FFF",
  },
  buttonContent: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "16px",
    },
    color: "#FFF",
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
    marginRight: "24px",
    paddingBottom: "15px",
  },
  buttonSave: {
    width: "110px",
    height: "40px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },
  buttonCansel: {
    marginLeft: "10px",
    width: "110px",
    height: "40px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.9)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },
  fieldHolder: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "35px",
    paddingTop: "35px",
  },
  fieldHolderSecond: {
    margin: "0 auto 35px",
    width: "93%",
    padding: "15px",
    borderRadius: " 0, 4 ",
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  },
  fieldBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "25%",
  },

  fieldBlockSecond: {
    display: "flex",
    justifyContent: "space-between",
  },
  formHolder: {
    minHeight: 440,
    maxHeight: {
      xs: 895,
      lg: 1024,
    },
    // overflowY: "scroll",
    // "&::-webkit-scrollbar": {
    //   width: "6px",
    //   backgroundColor: "#f0f0f0",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#ccc",
    //   borderRadius: "4px",
    // },
  },
  menuPanel: {
    background: "#B4CEE5",
    minHeight: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
  },

  inputTextField: {
    "& .MuiOutlinedInput-input": {
      fontSize: {
        xs: "12px",
        sm: "14px",
        md: "16px",
      },
    },
    "& .MuiSelect-select": {
      // paddingTop: '15px',
      // paddingBottom: '15px',
    },
  },

  labelText: {
    fontSize: {
      xs: "14px",
      sm: "1rem",
    },
  },
};

export default styles;
