import {
  RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST,
  RECEIVE_DOCS_GOOGLE_DRIVE_SUCCESS,
  RECEIVE_DOCS_GOOGLE_DRIVE_ERROR,
  ADD_NEW_FILES_GOOGLE_DRIVE_REQUEST,
  ADD_NEW_FILES_GOOGLE_DRIVE_SUCCESS,
  ADD_NEW_FILES_GOOGLE_DRIVE_ERROR,
  SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST,
  SEND_NEW_FILES_GOOGLE_DRIVE_SUCCESS,
  SEND_NEW_FILES_GOOGLE_DRIVE_ERROR,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";

export const receiveDocsGoogleDriveRequest = (data: any) => {
  return {
    type: RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST,
    data,
  };
};

export const receiveDocsGoogleDriveSuccess = (data: any) => {
  return {
    type: RECEIVE_DOCS_GOOGLE_DRIVE_SUCCESS,
    data,
  };
};

export const receiveDocsGoogleDriveError = (error) => {
  return {
    type: RECEIVE_DOCS_GOOGLE_DRIVE_ERROR,
    error,
  };
};

export const sendNewFilesGoogleDriveRequest = (data: any) => {
  return {
    type: SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST,
    data,
  };
};

export const sendNewFilesGoogleDriveSuccess = (data: any) => {
  return {
    type: SEND_NEW_FILES_GOOGLE_DRIVE_SUCCESS,
    data,
  };
};

export const sendNewFilesGoogleDriveError = (error: any) => {
  return {
    type: SEND_NEW_FILES_GOOGLE_DRIVE_ERROR,
    error,
  };
};

export const addNewFilesGoogleDriveRequest = (data, idFolder, id, types) => {
  return {
    type: ADD_NEW_FILES_GOOGLE_DRIVE_REQUEST,
    data,
    idFolder,
    id,
    types,
  };
};

export const addNewFilesGoogleDriveSuccess = (data: any) => {
  AlertService.success("Files was successfully added");
  return {
    type: ADD_NEW_FILES_GOOGLE_DRIVE_SUCCESS,
    data,
  };
};
export const addNewFilesGoogleDriveError = (error: any) => {
  return {
    type: ADD_NEW_FILES_GOOGLE_DRIVE_ERROR,
    error,
  };
};
