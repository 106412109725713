import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";

import { selectSuperAdmin } from "../../../../../modules/super-admin/selectors";
import styles from "../Modals.styles";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import {
  receiveLabelsListRequest,
  receiveTagsListRequest,
  deleteLabelRequest,
  deleteTagRequest,
} from "../../../../../modules/super-admin/actions";
import ModalFooter from "../../modal-footer/ModalFooter";
import { modalTypes } from "../../../../constants/field-constants";

const LabelTagDeleteModal = (props: any) => {
  const dispatch = useDispatch();
  const { labelsList, tagsList } = props;

  const [deleteId, setDeleteId] = useState(null);
  const [typeDelete, setTypeDelete] = useState(null);
  const [nameDelete, setNameDelete] = useState("");

  const handleOpen = (type, id) => {
    setTypeDelete(type);
    setDeleteId(id);
  };

  useEffect(() => {
    if (typeDelete === "labelDelete") {
      dispatch(receiveLabelsListRequest());
    }
    if (typeDelete === "tagDelete") {
      dispatch(receiveTagsListRequest());
    }
  }, [dispatch, typeDelete]);

  useEffect(() => {
    if (typeDelete === "labelDelete" && deleteId !== null) {
      const label = labelsList.find((item: any) => item.id === deleteId);

      setNameDelete(label.name);
    }
    if (typeDelete === "tagDelete" && deleteId !== null) {
      const tag = tagsList.find((item: any) => item.id === deleteId);
      setNameDelete(tag.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deleteId, typeDelete]);

  const handleDelete = (id: number | null) => {
    if (typeDelete === "labelDelete") {
      dispatch(deleteLabelRequest(id));
    }
    if (typeDelete === "tagDelete") {
      dispatch(deleteTagRequest(id));
    }
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openDeleteLabelTagModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader
              title={
                typeDelete === "labelDelete" ? "REMOVE LABEL" : "REMOVE TAG"
              }
            />
            <Box sx={styles.dataAppForDeleteModal}>
              <Typography sx={styles.contentContainer}>
                {`Are you sure you want to delete ${
                  typeDelete === "labelDelete" ? "label" : "tag"
                }`}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {`${nameDelete}`}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {`from the database?`}
              </Typography>
            </Box>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.DELETE}
              onSave={() => handleDelete(deleteId)}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const { labelsList, tagsList } = selectSuperAdmin(state);
  return { labelsList, tagsList };
}

export default connect(mapStateToProps)(LabelTagDeleteModal);
