import {
  RECEIVE_LABELS_LIST_ERROR,
  RECEIVE_LABELS_LIST_REQUEST,
  RECEIVE_LABELS_LIST_SUCCESS,
  CREATE_NEW_LABEL_REQUEST,
  CREATE_NEW_LABEL_ERROR,
  RECEIVE_TAGS_LIST_ERROR,
  RECEIVE_TAGS_LIST_REQUEST,
  RECEIVE_TAGS_LIST_SUCCESS,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_ERROR,
  DELETE_TAG_REQUEST,
  DELETE_TAG_ERROR,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_ERROR,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_ERROR,
  CREATE_NEW_TAG_REQUEST,
  CREATE_NEW_TAG_ERROR,
  GET_GOOGLE_DRIVE_REQUEST,
  GET_GOOGLE_DRIVE_SUCCESS,
  DISCONNECT_GOOGLE_DRIVE_REQUEST,
  DISCONNECT_GOOGLE_DRIVE_SUCCESS,
  DISCONNECT_GOOGLE_DRIVE_ERROR,
  CHECK_DRIVE_CONNECTION_REQUEST,
  CHECK_DRIVE_CONNECTION_SUCCESS,
  CHECK_DRIVE_CONNECTION_ERROR,
  GET_GOOGLE_DRIVE_ERROR,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";
// import AlertService from "../../../common/services/notification.service";

export const receiveLabelsListRequest = () => {
  return {
    type: RECEIVE_LABELS_LIST_REQUEST,
  };
};

export const receiveLabelsListSuccess = (labelsList) => {
  return {
    type: RECEIVE_LABELS_LIST_SUCCESS,
    labelsList,
  };
};

export const receiveLabelsListError = (error) => {
  return {
    type: RECEIVE_LABELS_LIST_ERROR,
    error,
  };
};

export const updateLabelRequest = (id, name) => {
  return {
    type: UPDATE_LABEL_REQUEST,
    id,
    name,
  };
};

export const updateLabelError = (error) => {
  return {
    type: UPDATE_LABEL_ERROR,
    error,
  };
};

export const deleteLabelRequest = (id) => {
  return {
    type: DELETE_LABEL_REQUEST,
    id,
  };
};

export const deleteLabelError = (error) => {
  return {
    type: DELETE_LABEL_ERROR,
    error,
  };
};

export const createNewLabelRequest = (label) => {
  return {
    type: CREATE_NEW_LABEL_REQUEST,
    label,
  };
};

export const createNewLabelError = (error) => {
  return {
    type: CREATE_NEW_LABEL_ERROR,
    error,
  };
};

//======== tags ===========//

export const receiveTagsListRequest = () => {
  return {
    type: RECEIVE_TAGS_LIST_REQUEST,
  };
};

export const receiveTagsListSuccess = (tagsList) => {
  return {
    type: RECEIVE_TAGS_LIST_SUCCESS,
    tagsList,
  };
};

export const receiveTagsListError = (error) => {
  return {
    type: RECEIVE_TAGS_LIST_ERROR,
    error,
  };
};

export const updateTagRequest = (id, name) => {
  return {
    type: UPDATE_TAG_REQUEST,
    id,
    name,
  };
};

export const updateTagError = (error) => {
  return {
    type: UPDATE_TAG_ERROR,
    error,
  };
};

export const deleteTagRequest = (id) => {
  return {
    type: DELETE_TAG_REQUEST,
    id,
  };
};

export const deleteTagError = (error) => {
  return {
    type: DELETE_TAG_ERROR,
    error,
  };
};

export const createNewTagRequest = (tag) => {
  return {
    type: CREATE_NEW_TAG_REQUEST,
    tag,
  };
};

export const createNewTagError = (error) => {
  return {
    type: CREATE_NEW_TAG_ERROR,
    error,
  };
};

//======== google drive ===========//

export const connectToDriveRequest = () => {
  return {
    type: GET_GOOGLE_DRIVE_REQUEST,
  };
};

export const connectToDriveSuccess = () => {
  return {
    type: GET_GOOGLE_DRIVE_SUCCESS,
    connection: true,
  };
};

export const connectToDriveError = (error) => {
  AlertService.error(`Connection to Google drive was failed. Error: ${error}`);
  return {
    type: GET_GOOGLE_DRIVE_ERROR,
    error,
  };
};

export const disconnectDriveRequest = () => {
  return {
    type: DISCONNECT_GOOGLE_DRIVE_REQUEST,
  };
};

export const disconnectDriveSuccess = (result) => {
  AlertService.success("You successfully disconnected from Randvast G-suite.");
  return {
    type: DISCONNECT_GOOGLE_DRIVE_SUCCESS,
    connection: false,
    result,
  };
};

export const disconnectDriveError = (error) => {
  AlertService.error("Something went wrong while trying to disconnect.");
  return {
    type: DISCONNECT_GOOGLE_DRIVE_ERROR,
    error,
  };
};

export const checkGoogleDriveConnectionRequest = () => {
  return {
    type: CHECK_DRIVE_CONNECTION_REQUEST,
  };
};

export const checkGoogleDriveConnectionSuccess = (connection) => {
  return {
    type: CHECK_DRIVE_CONNECTION_SUCCESS,
    connection,
  };
};

export const checkGoogleDriveConnectionError = (error) => {
  AlertService.error("Something went wrong while connection checking.");
  return {
    type: CHECK_DRIVE_CONNECTION_ERROR,
    error,
  };
};
