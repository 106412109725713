import { combineReducers } from "redux";
import {
  // RECEIVE_DRIVE_DOCS_ERROR,
  // RECEIVE_DRIVE_DOCS_REQUEST,
  // RECEIVE_DRIVE_DOCS_SUCCESS,
  // RECEIVE_LEADS_DATA_ERROR,
  // RECEIVE_LEADS_DATA_REQUEST,
  RECEIVE_LEADS_SUCCESS,
  CHECK_BEST_LEAD_REQUEST,
  CHECK_BEST_LEAD_SUCCESS,
  FIND_LEAD_FLAG,
  SET_CURRENT_USER_ID,
  SHOW_ASSIGNED_LEADS,
  SHOW_SCHEDULED_LEADS,
  SET_LEAD_ID,
  SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
} from "../../../common/constants/action-constants";

const checkBestLead = (state = {}, action) => {
  switch (action.type) {
    case CHECK_BEST_LEAD_REQUEST:
      return {
        isFetching: true,
      };
    case CHECK_BEST_LEAD_SUCCESS:
      return {
        isFetching: false,
        bestLead: action.bestLead,
      };

    default:
      return state;
  }
};

const showAssignedLeads = (state = false, action) => {
  switch (action.type) {
    case SHOW_ASSIGNED_LEADS:
      return action.assigned;

    default:
      return state;
  }
};

const showSkipAssignedLeads = (state = false, action) => {
  switch (action.type) {
    case SKIP_UNASSIGNED_LEADS_IN_LPS_RATING:
      return action.isSkipped;

    default:
      return state;
  }
};

const showScheduledLeads = (state = false, action) => {
  switch (action.type) {
    case SHOW_SCHEDULED_LEADS:
      return action.scheduled;

    default:
      return state;
  }
};

const setSelectedUserIds = (state = [], action) => {
  switch (action.type) {
    case SET_CURRENT_USER_ID:
      return action.selectedUserIds;

    default:
      return state;
  }
};

const setLeadFlag = (state = false, action) => {
  switch (action.type) {
    case FIND_LEAD_FLAG:
      return action.leadFlag;

    default:
      return state;
  }
};

const setLeadId = (state = {}, action) => {
  switch (action.type) {
    case SET_LEAD_ID:
      return action.leadId;

    default:
      return state;
  }
};

const receiveLeads = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_LEADS_SUCCESS:
      return action.leads;
    default:
      return state;
  }
};

const leads = combineReducers({
  freeLead: checkBestLead,
  assigned: showAssignedLeads,
  isSkipped: showSkipAssignedLeads,
  scheduled: showScheduledLeads,
  selectedUserIds: setSelectedUserIds,
  setLeadFlag,
  setLeadId,
  receive: receiveLeads,
  // receiveDriveFolderData,
  // error: errorFetchLeads,
});

export default leads;
