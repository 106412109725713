import { combineReducers } from "redux";
import settings from "./settings";
import searchResult from "./search";

const search = combineReducers({
  settings,
  searchResult,
});

export default search;
