import { call, put, takeLatest } from "redux-saga/effects";
import { getCurrentUserError, getCurrentUserSuccess } from "../actions/";
import {
  GET_CURRENT_USER_REQUEST,
  RECEIVE_LOGIN_REQUEST,
} from "../../../common/constants/action-constants";
import UserService from "../../../common/services/user.service";
import ApiService from "../../../common/services/api.service";
import AlertService from "../../../common/services/notification.service";
import { notificationMessage } from "../../../common/constants/notication-constants";
import { startCountLPSDataRequest } from "../../lps-form/actions";

/**first user checking after google login**/
function* getUserWithAuth(action: any) {
  try {
    // save token in LocalStorage
    if (action.token) {
      UserService.setToken = action.token;
    }
    const currentUser = yield call(ApiService.post, "/login", {
      code: action.code,
    });
    if (currentUser && currentUser.data.token) {
      UserService.setToken = currentUser.data.token;
    }
    const { name } = currentUser.data.user;
    if (currentUser && currentUser.data.default_lps_profile) {
      const selectedConfig = currentUser.data.default_lps_profile;
      // yield delay(2000);
      console.log("Lps process started");
      const ident = Date.now();
      // dispatch(startCountLPSDataRequest({ selectedConfig, selectedCity, ident }));
      yield put(
        startCountLPSDataRequest({
          selectedConfig,
          selectedCity: "All cities",
          ident,
        })
      );
      // AlertService.info(notificationMessage.LPS_CALCULATION_START);
    }

    yield put(getCurrentUserSuccess(currentUser.data.user));
    AlertService.success(`${notificationMessage.LOGIN_SUCCESS}, ${name}!`);
    action.navigate("/dashboard");
    action.cb();
  } catch (error) {
    yield put(getCurrentUserError(error));
    AlertService.error(notificationMessage.PERMISSIONS_ERROR);
    UserService.logout();
    action.navigate("/login");
  }
}

/**get user from server during application work**/
function* getCurrentUser(action: any) {
  try {
    const currentUser = yield call(ApiService.get, "/current-user");
    yield put(getCurrentUserSuccess(currentUser.data.user));
  } catch (error) {
    yield put(getCurrentUserError(error));
    AlertService.error(notificationMessage.PERMISSIONS_ERROR);
    UserService.logout();
    action.navigate("/login");
  }
}

export default function* actionLogin() {
  yield takeLatest(RECEIVE_LOGIN_REQUEST, getUserWithAuth);
  yield takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser);
}
