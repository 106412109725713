import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import StyledTextField from "../../styled-components/StyledTextField";
import styles from "../EntityEditor.styles";
import { connect, useDispatch } from "react-redux";
import { receiveCompanyRequest } from "../../../../modules/search/actions/search";
import { selectSearch } from "../../../../modules/search/selectors";

const searchOptions = [
  {
    label: "Kadaster personal number",
    value: "kadaster_personal_number",
  },
  {
    label: "Chamber commerce number",
    value: "chamber_commerce_number",
  },
  {
    label: "Company name",
    value: "company_name",
  },
];

const initialCompanyState = {
  fieldType: searchOptions[2].value || "",
  companyName: "",
  companyId: "",
  query: "",
  limit: 7,
};

const EntityCompanyBlock = ({ companyResult, onChange, entityCompany }) => {
  const dispatch = useDispatch();
  const [companyState, setCompanyState] = useState(initialCompanyState);
  const { fieldType, query, limit } = companyState;
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (Object.keys(entityCompany).length) {
      setValue(entityCompany);
    } else {
      setValue(null);
      setInputValue("");
    }
  }, [entityCompany]);

  useEffect(() => {
    let active = true;
    let newOptions: any = [];

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      setCompanyState(initialCompanyState);
      return undefined;
    }
    if (active) {
      if (value) {
        newOptions = [value];
      }
      if (companyResult?.length) {
        newOptions = [...newOptions, ...companyResult];
        onChange("entity_company_id", companyResult);
      }
      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, value, inputValue, companyResult]);

  useEffect(() => {
    if (value) {
      setCompanyState({
        ...companyState,
        // @ts-ignore
        companyName: value.company_name,
        // @ts-ignore
        companyId: value.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (key === "fieldType") {
      setCompanyState({
        ...companyState,
        [key]: event.target.value,
      });
    }
  };

  const handleChangeInput = (newInputValue) => {
    setInputValue(newInputValue);

    setCompanyState({
      ...companyState,
      query: newInputValue,
    });
  };

  useEffect(() => {
    if (query.length >= 2) {
      dispatch(receiveCompanyRequest({ query, fieldType, limit }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Box sx={styles.fieldHolder}>
      <Box sx={styles.fieldBlock}>
        <StyledTextField
          fullWidth
          select
          label="Search type"
          name="fieldType"
          margin="dense"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("fieldType", event)
          }
          value={companyState.fieldType}
        >
          {searchOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </Box>
      <Box sx={styles.fieldBlock}>
        <FormControl sx={{ mt: 1, mb: 0.5 }}>
          <Autocomplete
            id="company"
            // disabled={!area_filters.city}
            // sx={{ marginBottom: "5px" }}
            open={open && inputValue.length >= 2}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={options}
            freeSolo
            // loading={true}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            blurOnSelect
            fullWidth
            getOptionLabel={(option) =>
              option.company_name ? option.company_name : option
            }
            onChange={(e, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(e, newInputValue) =>
              handleChangeInput(newInputValue)
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Сompany" />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const { companyResult } = selectSearch(state);
  return { companyResult };
}

export default connect(mapStateToProps)(EntityCompanyBlock);
