import React, { useEffect, useState } from "react";
import { Box, Paper, Tab } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import styles from "../../Leadgen.styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Maps from "./Maps";
import GoogleDrive from "./GoogleDrive";
import { receiveDocsGoogleDriveRequest } from "../../actions/docsGoogleDrive";
import { selectLeadgen } from "../../selectors";
import { showAddressOnMap } from "../../actions/objects";

interface MapGoogleDriveBlockProps {
  mapStyles: object;
  address?: any;
  entityId?: string;
  objectId?: string;
  addressForMap?: any;
  entityObjectsList?: any;
  objectsAmount?: any;
}

const initialTab = "map";

const MapGoogleDriveBlock = (props: MapGoogleDriveBlockProps) => {
  const { mapStyles, address, entityId, objectId } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialTab);
  const [type, setType] = useState("");
  const [changeAddress, setChangeAddress] = useState("");
  const [id, setId] = useState("");
  const [addressForMapDisplaying, setAddressForMapsDisplaying] = useState("");

  useEffect(() => {
    if (entityId) {
      setType("entity");
      setId(entityId);
    }
    if (objectId) {
      setType("object");
      setId(objectId);
    }
    setValue(initialTab);
    setAddressForMapsDisplaying(address);
    if (address) {
      setChangeAddress(address);
    }
  }, [entityId, objectId, address]);

  useEffect(() => {
    if (value === "googleDrive") {
      if (type === "entity") {
        dispatch(receiveDocsGoogleDriveRequest({ type, id: entityId }));
      }
      if (type === "object") {
        dispatch(receiveDocsGoogleDriveRequest({ type, id: objectId }));
      }
    }
    if (value === "map") {
      dispatch(showAddressOnMap(changeAddress));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Paper sx={styles.containerMap}>
        <TabContext value={value}>
          <Box sx={styles.boxTabs}>
            <TabList
              onChange={handleChangeTabs}
              variant="fullWidth"
              aria-label="tabs API"
            >
              <Tab label="Map" value="map" sx={styles.contentTitleMain} />
              <Tab
                label="Google Drive"
                value="googleDrive"
                sx={styles.contentTitleMain}
              />
            </TabList>
          </Box>
          <TabPanel value="map" sx={styles.contentTabPanel}>
            <Maps
              mapStyles={mapStyles}
              addressForMapDisplaying={addressForMapDisplaying}
              tab={value}
            />
          </TabPanel>
          <TabPanel value="googleDrive" sx={styles.contentTabPanel}>
            <GoogleDrive type={type} id={id} />
          </TabPanel>
        </TabContext>
      </Paper>
    </>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, objectsAmount, addressForMap } =
    selectLeadgen(state);
  return { entityObjectsList, objectsAmount, addressForMap };
}

export default connect(mapStateToProps)(MapGoogleDriveBlock);
