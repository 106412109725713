import * as React from "react";
import { Button, Box, Typography, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  GoogleOAuthProvider,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import UserService from "../../common/services/user.service";
import styles from "./Login.styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { receiveLoginRequest } from "./actions";
import { receiveSettingsRequest } from "../search/actions/settings";
import { receiveLPSConfigsRequest } from "../lps-form/actions";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseGoogle = (response) => {
    // const user = response.profileObj;
    // const token = response.tokenId;
    // const token3 = response.credential;
    let code;
    let token;

    if (response.code) {
      code = response.code;
      token = "";
    }
    if (response.credential) {
      code = "";
      token = response.credential;
    }

    if (response) {
      dispatch(
        receiveLoginRequest(code, token, navigate, () => {
          dispatch(
            receiveSettingsRequest()
          ); /**search settings for current user**/
          dispatch(receiveLPSConfigsRequest()); /**lps configs list**/
          // dispatch(receiveUsersDataRequest()); /**users list for updating entities responsible person**/
        })
      );
    } else {
      console.log("Something went wrong");
    }
  };

  const GoogleLoginButton = () => {
    const handleGoogleAuth = useGoogleLogin({
      flow: "auth-code",

      onSuccess: async (codeResponse) => responseGoogle(codeResponse),
      onError: () => console.log("Login Failed"),
    });

    useGoogleOneTapLogin({
      onSuccess: (credentialResponse) => {
        responseGoogle(credentialResponse);
      },
      onError: () => {
        console.error("One Tap login failed");
      },
      auto_select: true,
      use_fedcm_for_prompt: true,
    });

    return (
      <Button
        variant="contained"
        sx={styles.loginButton}
        onClick={() => handleGoogleAuth()}
      >
        SIGN IN WITH GOOGLE
      </Button>
    );
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={styles.root}
      >
        <Paper sx={styles.loginContainer} elevation={3}>
          <Box sx={styles.imageBox}>
            <img src="images/logo.jpg" alt="Randvast logo" />
          </Box>
          <Box sx={styles.contentBox}>
            <Box sx={styles.textHolder}>
              <Typography component="div" variant="h1" sx={styles.welcomeTitle}>
                Welcome to RANDVAST workspace!
              </Typography>
              <Typography
                component="div"
                variant="h2"
                sx={styles.welcomeSubTitle}
              >
                Please log in to continue
              </Typography>
            </Box>
            <Box sx={styles.buttonHolder}>
              <GoogleOAuthProvider
                // @ts-ignore
                clientId={UserService.googleClient.id}
              >
                {/*<GoogleLogin*/}
                {/*  onSuccess ={responseGoogle}*/}
                {/*  onError={() => console.log('Login failed')}*/}
                {/*  auto_select = {true}*/}
                {/*/>*/}
                <GoogleLoginButton />

                {/*<GoogleLogin*/}
                {/*  // @ts-ignore*/}
                {/*  clientId={UserService.googleClient.id}*/}
                {/*  onSuccess={responseGoogle}*/}
                {/*  onFailure={responseGoogle}*/}
                {/*  cookiePolicy={"single_host_origin"}*/}
                {/*  render={(renderProps) => (*/}
                {/*    <Button*/}
                {/*      variant="contained"*/}
                {/*      sx={styles.loginButton}*/}
                {/*      onClick={renderProps.onClick}*/}
                {/*    >*/}
                {/*      SIGN IN WITH GOOGLE*/}
                {/*    </Button>*/}
                {/*  )}*/}
                {/*/>*/}
              </GoogleOAuthProvider>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
}

export default Login;
