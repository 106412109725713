import {
  CHECK_BEST_LEAD_REQUEST,
  CHECK_BEST_LEAD_SUCCESS,
  CHECK_BEST_LEAD_ERROR,
  CHECK_FREE_LEAD_REQUEST,
  CHECK_FREE_LEAD_SUCCESS,
  CHECK_FREE_LEAD_ERROR,
  GO_TO_NEXT_LEAD,
  SHOW_ASSIGNED_LEADS,
  SHOW_SCHEDULED_LEADS,
  SET_CURRENT_USER_ID,
  FIND_LEAD_FLAG,
  PREV_LPS_LEAD,
  NEXT_LPS_LEAD,
  SET_LEAD_ID,
  RECEIVE_LEADS_REQUEST,
  RECEIVE_LEADS_SUCCESS,
  RECEIVE_LEADS_ERROR,
  SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
} from "../../../common/constants/action-constants";

export const goToNextLead = () => {
  return {
    type: GO_TO_NEXT_LEAD,
  };
};

export const checkBestLeadRequest = (navigate) => {
  return {
    type: CHECK_BEST_LEAD_REQUEST,
    navigate,
  };
};

export const checkBestLeadSuccess = (bestLead, leadIdIndex) => {
  return {
    type: CHECK_BEST_LEAD_SUCCESS,
    bestLead: bestLead.data,
    indexLPSItem: leadIdIndex,
  };
};

export const checkBestLeadError = (error) => {
  return {
    type: CHECK_BEST_LEAD_ERROR,
    error,
  };
};

export const checkFreeLeadRequest = () => {
  return {
    type: CHECK_FREE_LEAD_REQUEST,
  };
};

export const checkFreeLeadSuccess = (freeLead) => {
  return {
    type: CHECK_FREE_LEAD_SUCCESS,
    freeLead,
  };
};

export const checkFreeLeadError = (error) => {
  return {
    type: CHECK_FREE_LEAD_ERROR,
    error,
  };
};

export const showAssignedLeads = (assigned) => {
  return {
    type: SHOW_ASSIGNED_LEADS,
    assigned,
  };
};

export const skipUnassignedLeadsFlag = (isSkipped) => {
  return {
    type: SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
    isSkipped,
  };
};

export const showScheduledLeads = (scheduled) => {
  return {
    type: SHOW_SCHEDULED_LEADS,
    scheduled,
  };
};

export const setSelectedUsers = (selectedUserIds) => {
  return {
    type: SET_CURRENT_USER_ID,
    selectedUserIds,
  };
};

export const prevLPSLead = (navigate) => {
  return {
    type: PREV_LPS_LEAD,
    navigate,
  };
};

export const nextLPSLead = (navigate) => {
  return {
    type: NEXT_LPS_LEAD,
    navigate,
  };
};

export const findLeadFlag = (findLead) => {
  return {
    type: FIND_LEAD_FLAG,
    findLeadFlag: findLead,
  };
};

export const setLeadId = (leadId) => {
  return {
    type: SET_LEAD_ID,
    leadId,
  };
};

export const receiveLeadsRequest = (data) => {
  return {
    type: RECEIVE_LEADS_REQUEST,
    data,
  };
};

export const receiveLeadsSuccess = (leads) => {
  return {
    type: RECEIVE_LEADS_SUCCESS,
    leads: leads.data,
  };
};

export const receiveLeadsError = (error) => {
  return {
    type: RECEIVE_LEADS_ERROR,
    error,
  };
};
