import { combineReducers } from "redux";
import {
  CREATE_NEW_ENTITY_SUCCESS,
  CREATE_NEW_ENTITY_ERROR,
  RECEIVE_POSTCODE_SUCCESS,
  RESET_POSTCODE,
} from "../../../common/constants/action-constants";

const createNewEntity = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_ENTITY_SUCCESS:
      return action.entity;

    default:
      return state;
  }
};

const createNewEntityError = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_ENTITY_ERROR:
      return action.error;

    default:
      return state;
  }
};
const postcodeAddress = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_POSTCODE_SUCCESS:
      return action.result;
    case RESET_POSTCODE:
      return {};

    default:
      return state;
  }
};

const createEntity = combineReducers({
  create: createNewEntity,
  error: createNewEntityError,
  postcodeAddress: postcodeAddress,
});

export default createEntity;
