import {
  SEARCH_EDIT_SETTINGS_ERROR,
  SEARCH_EDIT_SETTINGS_REQUEST,
  SEARCH_EDIT_SETTINGS_SUCCESS,
  SEARCH_RECEIVE_SETTINGS_ERROR,
  SEARCH_RECEIVE_SETTINGS_REQUEST,
  SEARCH_RECEIVE_SETTINGS_SUCCESS,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";

/** receive Settings **/
export const receiveSettingsRequest = () => {
  return {
    type: SEARCH_RECEIVE_SETTINGS_REQUEST,
  };
};

export const receiveSettingsSuccess = (settings) => {
  return {
    type: SEARCH_RECEIVE_SETTINGS_SUCCESS,
    settings: settings.data,
  };
};

export const receiveSettingsError = (error) => {
  return {
    type: SEARCH_RECEIVE_SETTINGS_ERROR,
    error,
  };
};

/** edit Settings **/

export const editSettingsRequest = (data) => {
  return {
    type: SEARCH_EDIT_SETTINGS_REQUEST,
    data,
  };
};

export const editSettingsSuccess = (settings) => {
  AlertService.success("Settings configuration was successfully updated!");
  return {
    type: SEARCH_EDIT_SETTINGS_SUCCESS,
    settings: settings.data,
  };
};

export const editSettingsError = (error) => {
  return {
    type: SEARCH_EDIT_SETTINGS_ERROR,
    error,
  };
};
