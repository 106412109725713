import { createSelector } from "reselect";

const lpsState = (state) => {
  if (state && state.lps) {
    return state.lps;
  }
  return null;
};

const lpsSelector = createSelector(
  [lpsState],
  (state) => state //return lps
);

const lpsReceiveLpsConfigsSelector = createSelector(
  [lpsSelector],
  (state) => state.lpsConfigs
);

const lpsProcessingSelector = createSelector(
  [lpsSelector],
  (state) => state.processing
);

const indexLPSItemSelector = createSelector(
  [lpsSelector],
  (state) => state.indexLPSItem
);

export const selectLPS: any = createSelector(
  [
    lpsSelector,
    lpsReceiveLpsConfigsSelector,
    lpsProcessingSelector,
    indexLPSItemSelector,
  ],
  (lps, lpsConfigs, processing, indexLPSItem) => {
    const countOfLeads = lps?.processing?.result?.lpc?.length
      ? lps.processing.result.lpc.length
      : 0;
    const isCalculated = lps?.processing?.isCalculated;
    const isFetching = lps?.processing?.isFetching;

    return {
      lps,
      lpsConfigs,
      processing,
      countOfLeads,
      indexLPSItem,
      isCalculated,
      isFetching,
    };
  }
);
