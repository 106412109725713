const styles = {
  container: {
    maxHeight: "100vh",
    height: "100%",
    overflowY: "hidden",
  },
  typography: {
    fontSize: {
      xs: "16px",
      sm: "20px",
      md: "24px",
      lg: "28px",
      xl: "34px",
    },
    color: "#495057",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "25px",
  },
  imgBg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    // height: "100%",
  },
  imgStyles: {
    display: "block",
    maxWidth: "100%",
    height: "auto",
  },
};

export default styles;
