import StyledTextField from "../../../common/components/styled-components/StyledTextField";
import React, { useEffect, useState } from "react";
import { Autocomplete, Box, MenuItem } from "@mui/material";
import styles from "../LPSForm.styles";
import { lpsFormTypes } from "../../../common/constants/field-constants";
import { selectSearch } from "../../search/selectors";
import { connect, useDispatch } from "react-redux";
import { receiveCityRequest } from "../../search/actions/search";
// import { selectLPS } from '../selectors';

const calculationOptions = [
  {
    label: "Industrial",
    value: "industrial",
  },
  {
    label: "Non-industrial",
    value: "non-industrial",
  },
  {
    label: "Kadaster",
    value: "kadaster",
  },
];

const configOptions = [
  {
    label: "Public",
    value: "public",
  },
  {
    label: "Private",
    value: "private",
  },
];

const objectOptions = [
  {
    label: "G0",
    value: "G0",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "All items",
    value: "all",
  },
];

const entityOptions = [
  {
    label: "All entities",
    value: "all_ent",
  },
  {
    label: "Without blacklisted",
    value: "exclude_blacklisted",
  },
];

const LPSGeneralFieldsBlock = ({
  onChange,
  calculationType,
  generalFields,
  citiesResult,
  actionChangeCity,
  resetForm,
  lpsConfigCities,
  configLpsCities,
  processing,
  isFilledFields,
  changeFilledFields,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [optionsForCitiesGroup, setOptionsForCitiesGroup] = useState<any[]>([]);
  const [inputMultipleValue, setInputMultipleValue] = React.useState("");
  const [multipleValue, setMultipleValue] = React.useState([]);

  useEffect(() => {
    const filterLpsConfigCities = configLpsCities.map((item) => {
      return { city: item.city_name };
    });
    setMultipleValue(filterLpsConfigCities);
  }, [configLpsCities]);

  useEffect(() => {
    let active = true;
    let newMultipleOptions: any = [];

    if (inputMultipleValue === "") {
      setOptionsForCitiesGroup(multipleValue ? [...multipleValue] : []);
      return undefined;
    }

    if (active) {
      if (multipleValue) {
        newMultipleOptions = multipleValue;
      }
      if (citiesResult) {
        newMultipleOptions = [...newMultipleOptions, ...citiesResult];
      }
      setOptionsForCitiesGroup(newMultipleOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, citiesResult, multipleValue, inputMultipleValue]);

  useEffect(() => {
    actionChangeCity(multipleValue);
    if (!!Object.keys(isFilledFields).length) {
      changeFilledFields({ ...isFilledFields, isFilledFieldCity: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleValue]);

  useEffect(() => {
    if (resetForm) {
      setMultipleValue([]);
    }
  }, [resetForm]);

  useEffect(() => {
    if (generalFields.name) {
      changeFilledFields({ ...isFilledFields, isFilledFieldName: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalFields.name]);

  useEffect(() => {
    if (generalFields.config_type) {
      changeFilledFields({ ...isFilledFields, isFilledFieldConfigType: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalFields.config_type]);

  useEffect(() => {
    if (generalFields.object_type) {
      changeFilledFields({ ...isFilledFields, isFilledFieldObjectType: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalFields.object_type]);

  useEffect(() => {
    if (generalFields.entity_type) {
      changeFilledFields({ ...isFilledFields, isFilledFieldEntityType: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalFields.entity_type]);

  useEffect(() => {
    if (!open && multipleValue !== null) {
      setOptionsForCitiesGroup([]);
    }
  }, [open, multipleValue]);

  const shallowEqualityCheck = (obj1: any, obj2: any) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  };

  const handleMultipleChange = (newInputMultipleValue: any) => {
    setInputMultipleValue(newInputMultipleValue);

    if (newInputMultipleValue.length >= 2) {
      const requestData = {
        inputValue: newInputMultipleValue,
      };
      dispatch(receiveCityRequest(requestData));
    }
  };

  const comparingOptionAndValue = (option, value) => {
    if (Array.isArray(option)) {
      return option.some((item) => shallowEqualityCheck(item, value));
    } else {
      return option === value;
    }
  };
  return (
    <Box sx={styles.fieldHolder}>
      <Box sx={styles.fieldBlock}>
        <StyledTextField
          fullWidth
          required
          select
          label="Calculation type"
          margin="dense"
          defaultValue=""
          sx={styles.inputTextField}
          slotProps={{
            inputLabel: {
              sx: {
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "1rem",
                },
              },
            },
          }}
          onChange={(e) => onChange("generalFields", "calculation_type", e)}
          value={generalFields?.calculation_type || ""}
        >
          {calculationOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
        {calculationType === lpsFormTypes.NON_INDUSTRIAL ? (
          <Autocomplete
            sx={{ marginBottom: "15px" }}
            multiple
            open={open && inputMultipleValue.length >= 2}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={optionsForCitiesGroup}
            loading={processing}
            filterOptions={(x) => x}
            autoComplete
            value={multipleValue}
            includeInputInList
            filterSelectedOptions
            blurOnSelect
            fullWidth
            getOptionLabel={(option) => {
              return option.city;
            }}
            isOptionEqualToValue={(option, value) => {
              return comparingOptionAndValue(option, value);
            }}
            onChange={(e, newValue, reason) => {
              const cities = new Set(
                multipleValue.map((multiple: any) => multiple.city)
              );
              setOptionsForCitiesGroup(
                newValue
                  ? [newValue, ...optionsForCitiesGroup]
                  : optionsForCitiesGroup
              );
              // @ts-ignore
              setMultipleValue([
                ...multipleValue,
                ...newValue.filter((o) => !cities.has(o.city)),
              ]);
              if (reason === "removeOption" || reason === "clear") {
                // @ts-ignore
                setMultipleValue([...newValue]);
              }
            }}
            onInputChange={(e, newInputMultipleValue) =>
              handleMultipleChange(newInputMultipleValue)
            }
            renderInput={(params) => (
              <StyledTextField
                {...params}
                required
                error={
                  !!Object.keys(isFilledFields).length &&
                  !isFilledFields.isFilledFieldCity &&
                  isFilledFields.isFilledFieldCity !== undefined
                }
                label="City"
                sx={styles.inputTextField}
                slotProps={{
                  inputLabel: {
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "1rem",
                      },
                    },
                  },
                }}
                margin="dense"
              />
            )}
          />
        ) : (
          <StyledTextField
            fullWidth
            disabled
            label="City"
            sx={styles.inputTextField}
            slotProps={{
              inputLabel: {
                sx: {
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "1rem",
                  },
                },
              },
            }}
            margin="dense"
            onChange={(e) => onChange("generalFields", "city", e)}
            value={generalFields?.city}
          />
        )}
      </Box>
      <Box sx={styles.fieldBlock}>
        <StyledTextField
          fullWidth
          required
          error={
            !!Object.keys(isFilledFields).length &&
            !isFilledFields.isFilledFieldName &&
            isFilledFields.isFilledFieldName !== undefined
          }
          label="Config name"
          sx={styles.inputTextField}
          slotProps={{
            inputLabel: {
              sx: {
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "1rem",
                },
              },
            },
          }}
          margin="dense"
          onChange={(e) => onChange("generalFields", "name", e)}
          value={generalFields?.name}
        />
        <StyledTextField
          fullWidth
          error={
            !!Object.keys(isFilledFields).length &&
            !isFilledFields.isFilledFieldConfigType &&
            isFilledFields.isFilledFieldConfigType !== undefined
          }
          required
          select
          label="Config type"
          sx={styles.inputTextField}
          slotProps={{
            inputLabel: {
              sx: {
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "1rem",
                },
              },
            },
          }}
          margin="dense"
          defaultValue=""
          onChange={(e) => onChange("generalFields", "config_type", e)}
          value={generalFields?.config_type || ""}
        >
          {configOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </Box>
      <Box sx={styles.fieldBlock}>
        <StyledTextField
          fullWidth
          error={
            !!Object.keys(isFilledFields).length &&
            !isFilledFields.isFilledFieldObjectType &&
            isFilledFields.isFilledFieldObjectType !== undefined
          }
          required
          select
          label="Objects type"
          sx={styles.inputTextField}
          slotProps={{
            inputLabel: {
              sx: {
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "1rem",
                },
              },
            },
          }}
          margin="dense"
          defaultValue=""
          onChange={(e) => onChange("generalFields", "object_type", e)}
          value={generalFields?.object_type || ""}
        >
          {objectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          fullWidth
          error={
            !!Object.keys(isFilledFields).length &&
            !isFilledFields.isFilledFieldEntityType &&
            isFilledFields.isFilledFieldEntityType !== undefined
          }
          required
          select
          label="Entities type"
          sx={styles.inputTextField}
          slotProps={{
            inputLabel: {
              sx: {
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "1rem",
                },
              },
            },
          }}
          margin="dense"
          defaultValue=""
          onChange={(e) => onChange("generalFields", "entity_type", e)}
          value={generalFields?.entity_type || ""}
        >
          {entityOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const { citiesResult, processing } = selectSearch(state);
  return { citiesResult, processing };
}

export default connect(mapStateToProps)(LPSGeneralFieldsBlock);
