import { combineReducers } from "redux";
import {
  DELETE_TAG_ERROR,
  RECEIVE_LABELS_LIST_ERROR,
  RECEIVE_LABELS_LIST_SUCCESS,
  RECEIVE_TAGS_LIST_ERROR,
  RECEIVE_TAGS_LIST_SUCCESS,
  UPDATE_TAG_ERROR,
  UPDATE_LABEL_ERROR,
  DELETE_LABEL_ERROR,
  CREATE_NEW_LABEL_ERROR,
  CREATE_NEW_TAG_ERROR,
  GET_GOOGLE_DRIVE_ERROR,
  DISCONNECT_GOOGLE_DRIVE_ERROR,
  CHECK_DRIVE_CONNECTION_SUCCESS,
  GET_GOOGLE_DRIVE_SUCCESS,
  DISCONNECT_GOOGLE_DRIVE_SUCCESS,
} from "../../../common/constants/action-constants";

const receiveLabelsList = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_LABELS_LIST_SUCCESS:
      return action.labelsList;
    default:
      return state;
  }
};

const receiveTagsList = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_TAGS_LIST_SUCCESS:
      return action.tagsList;
    default:
      return state;
  }
};

const driveConnectionActive = (state = {}, action) => {
  switch (action.type) {
    case CHECK_DRIVE_CONNECTION_SUCCESS:
    case GET_GOOGLE_DRIVE_SUCCESS:
    case DISCONNECT_GOOGLE_DRIVE_SUCCESS:
      return action.connection;
    default:
      return state;
  }
};

const error = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_LABELS_LIST_ERROR:
    case RECEIVE_TAGS_LIST_ERROR:
    case UPDATE_TAG_ERROR:
    case DELETE_TAG_ERROR:
    case UPDATE_LABEL_ERROR:
    case DELETE_LABEL_ERROR:
    case CREATE_NEW_LABEL_ERROR:
    case CREATE_NEW_TAG_ERROR:
    case GET_GOOGLE_DRIVE_ERROR:
    case DISCONNECT_GOOGLE_DRIVE_ERROR:
      return action.error;
    default:
      return state;
  }
};

const superAdmin = combineReducers({
  labelsList: receiveLabelsList,
  tagsList: receiveTagsList,
  driveConnection: driveConnectionActive,
  error,
});

export default superAdmin;
