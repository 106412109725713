import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import styles from "./LeadgenNoData.styles";

const LeadgenNoData = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/leadgen") {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "";
    };
  }, [location.pathname]);
  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.typography}>
        Nothing to show yet. Use the search to find an entity or an object of
        interest
      </Typography>
      <Box sx={styles.imgBg}>
        <img
          style={styles.imgStyles}
          src="images/empty-page.png"
          alt="empty page"
        />
      </Box>
    </Box>
  );
};

export default LeadgenNoData;
