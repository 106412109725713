const styles = {
  dataBox: {
    "& button": {
      minHeight: {
        xs: "48px",
        sm: "50px",
        md: "55px",
        lg: "60px",
      },
    },
    margin: 0,
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
  },
  contentHolder: {
    bgcolor: "background.paper",
    width: "100%",
  },
  tabPanel: {
    p: 2,
    maxHeight: {
      // xs: 895,
      // lg: 1024,
      xs: "80vh",
    },
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
};

export default styles;
