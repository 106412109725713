import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Typography, Box } from "@mui/material";

import DefaultModal from "../../default-modal/DefaultModal";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { types } from "../../../helpers/eventEmitter";
import { modalTypes } from "../../../../constants/field-constants";
import { selectAdmin } from "../../../../../modules/admin/selectors";
import styles from "../Modals.styles";
import { updateEntityRequest } from "../../../../../modules/leadgen/actions/entityDetails";

const ObjectsUnlinkModal = () => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState(null);
  const [receivedObjectList, setObjectList] = useState([]);
  const [objectId, setObjectId] = useState(null);
  const [type, setUnlinkType] = useState("");

  const handleOpen = ({ unLinkType, entityId, objectList, object_id }) => {
    if (entityId !== null) {
      setEntityId(entityId);
    }
    if (unLinkType) {
      setUnlinkType(unLinkType);
    }
    if (objectList) {
      setObjectList(objectList);
    }
    if (object_id) {
      setObjectId(object_id);
    }
  };

  const handleUnlink = () => {
    let data = {};
    let objectsForUnlink: any[] = [];
    if (type === "current_object") {
      objectsForUnlink = receivedObjectList.filter(
        (i: any) => i.id === objectId
      );
      objectsForUnlink = objectsForUnlink.map((object: any) => object.id);
    }
    if (type === "all_objects_by_city") {
      objectsForUnlink = receivedObjectList.map((object: any) => object.id);
    }
    if (type === "all_objects") {
      objectsForUnlink = receivedObjectList.map(
        (object: any) => object.object_id
      );
    }

    data = {
      objectsForUnlink,
    };
    dispatch(updateEntityRequest(true, ["objects"], currentEntityId, data));
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openAllObjectUnlinkModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title="UNLINK OBJECTS" />
            {type === "all_objects" && (
              <Box sx={styles.dataAppForDeleteModal}>
                <Typography sx={styles.contentContainer}>
                  {`Are you sure you want to unlink all objects from current entity?`}
                </Typography>
              </Box>
            )}
            {type === "all_objects_by_city" && (
              <Box sx={styles.dataApp}>
                <Typography sx={styles.contentContainer}>
                  {`Are you sure you want to unlink all objects in this city from current entity?`}
                </Typography>
              </Box>
            )}
            {type === "current_object" && (
              <Box sx={styles.dataApp}>
                <Typography sx={styles.contentContainer}>
                  {`Are you sure you want to unlink this object from current entity?`}
                </Typography>
              </Box>
            )}
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.UNLINK}
              onSave={() => handleUnlink()}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const users = selectAdmin(state);
  return users;
}

export default connect(mapStateToProps)(ObjectsUnlinkModal);
