import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomTabs from "../../common/components/tabs/Tabs";
import EntityEditor from "../../common/components/entity-editor/EntityEditor";
import ObjectEditor from "../../common/components/object-editor/ObjectEditor";
import SplitButton from "../../common/components/split-button/SplitButton";
import { entityTypes } from "../../common/constants/field-constants";
import styles from "./AddNewEntity.styles";

const options = [entityTypes.PERSON, entityTypes.COMPANY];

const AddNewEntity = () => {
  const [entityType, setEntityType] = useState(entityTypes.PERSON);
  const handleChange = (index: number) => {
    setEntityType(options[index]);
  };

  const CreateEntityHolder = () => {
    return (
      <React.Fragment>
        <SplitButton
          type={entityType}
          options={options}
          onChange={handleChange}
        />
        <EntityEditor
          //@ts-ignore
          type={entityType}
        />
      </React.Fragment>
    );
  };

  const tabContent: any = [
    {
      type: "Entity",
      content: <CreateEntityHolder />,
    },
    {
      type: "Object",
      content: <ObjectEditor />,
    },
  ];

  return (
    <>
      <Box sx={styles.holder}>
        <CustomTabs children={tabContent} />
      </Box>
    </>
  );
};

export default AddNewEntity;
