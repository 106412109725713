import {
  RECEIVE_LPS_CONFIGS_REQUEST,
  RECEIVE_LPS_CONFIGS_SUCCESS,
  RECEIVE_LPS_CONFIGS_ERROR,
  CREATE_EDIT_LPS_CONFIG_ERROR,
  CREATE_EDIT_LPS_CONFIG_REQUEST,
  CREATE_EDIT_LPS_CONFIG_SUCCESS,
  DELETE_LPS_CONFIG_REQUEST,
  DELETE_LPS_CONFIG_SUCCESS,
  DELETE_LPS_CONFIG_ERROR,
  RUN_PROCESSING_LPS_REQUEST,
  FINISH_PROCESSING_LPS_SUCCESS,
  FINISH_PROCESSING_LPS_ERROR,
  CHECK_LPS_USAGE_REQUEST,
  CHECK_LPS_USAGE_SUCCESS,
  CHECK_LPS_USAGE_ERROR,
  SET_LPS_LEAD_INDEX,
  RECALCULATE_DEFAULT_PROFILE,
  RESET_CURRENT_CALCULATION_REQUEST,
  RESET_CURRENT_CALCULATION_SUCCESS,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";
import UserService from "../../../common/services/user.service";

/** receive LPS configs list **/
export const receiveLPSConfigsRequest = () => {
  return {
    type: RECEIVE_LPS_CONFIGS_REQUEST,
  };
};

export const receiveLPSConfigsSuccess = (lpsConfigs) => {
  return {
    type: RECEIVE_LPS_CONFIGS_SUCCESS,
    lpsConfigs,
  };
};

export const receiveLPSConfigsError = (error) => {
  return {
    type: RECEIVE_LPS_CONFIGS_ERROR,
    error,
  };
};

export const createEditLPSConfigsRequest = (data) => {
  return {
    type: CREATE_EDIT_LPS_CONFIG_REQUEST,
    data,
  };
};

export const createEditLPSConfigsSuccess = (message) => {
  AlertService.success(message);
  return {
    type: CREATE_EDIT_LPS_CONFIG_SUCCESS,
  };
};

export const createEditLPSConfigsError = (error) => {
  AlertService.error(`SOMETHING WENT WRONG. Error: ${error}`);
  return {
    type: CREATE_EDIT_LPS_CONFIG_ERROR,
    error,
  };
};

export const deleteLPSConfigRequest = (id) => {
  return {
    type: DELETE_LPS_CONFIG_REQUEST,
    id,
  };
};

export const deleteLPSConfigSuccess = (message) => {
  AlertService.success(message);
  return {
    type: DELETE_LPS_CONFIG_SUCCESS,
  };
};

export const deleteLPSConfigError = (error) => {
  AlertService.error(`SOMETHING WENT WRONG. Error: ${error}`);
  return {
    type: DELETE_LPS_CONFIG_ERROR,
    error,
  };
};

export const startCountLPSDataRequest = (query) => {
  return {
    type: RUN_PROCESSING_LPS_REQUEST,
    query,
  };
};

export const resetCurrentCalculationRequest = () => {
  // @ts-ignore
  const ident = UserService.LPCIdent;
  return {
    type: RESET_CURRENT_CALCULATION_REQUEST,
    ident,
  };
};

export const resetCurrentCalculationSuccess = (data) => {
  AlertService.info(data.message);
  return {
    type: RESET_CURRENT_CALCULATION_SUCCESS,
    data,
  };
};

export const finishCountLPSDataSuccess = (result) => {
  AlertService.success("LPS process completed");
  return {
    type: FINISH_PROCESSING_LPS_SUCCESS,
    result: result,
  };
};

export const finishCountLPSDataError = (error) => {
  return {
    type: FINISH_PROCESSING_LPS_ERROR,
    error,
  };
};

export const checkLpsUsageRequest = (needAlertFlag = false) => {
  // @ts-ignore
  const ident = UserService.LPCIdent;
  return {
    type: CHECK_LPS_USAGE_REQUEST,
    needAlertFlag,
    ident,
  };
};

export const checkLpsUsageSuccess = (canUseLps) => {
  AlertService.info(canUseLps);

  return {
    type: CHECK_LPS_USAGE_SUCCESS,
    canUseLps,
  };
};

export const checkLpsUsageError = (error) => {
  return {
    type: CHECK_LPS_USAGE_ERROR,
    error,
  };
};

export const setLPSItem = (index) => {
  return {
    type: SET_LPS_LEAD_INDEX,
    indexLPSItem: index,
  };
};

// export const skipUnassignedLeadsFlag = (isSkipped) => {
//   console.log('isSkipped', isSkipped);
//   return {
//     type: SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
//     isSkipped,
//   };
// };

export const calcDefaultProfile = (isCalculated) => {
  return {
    type: RECALCULATE_DEFAULT_PROFILE,
    isCalculated,
  };
};
