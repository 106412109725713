import * as React from "react";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import styles from "./Layout.styles";
import Header from "../../common/components/header/Header";
import SideBar from "../../common/components/sidebar/SideBar";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { receiveLPSConfigsRequest } from "../../modules/lps-form/actions";
import { useDispatch } from "react-redux";
import { drawerWidth } from "../../common/components/lps-menu/LPSMenu";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "lpsMenuOpen",
})<{
  open?: boolean;
  lpsMenuOpen?: boolean;
}>(({ theme, open, lpsMenuOpen }) => ({
  width: "100%",
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  ...(lpsMenuOpen && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: `${drawerWidth}px`,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  padding: 0,
  alignItems: "center",
  ...theme.mixins.toolbar,
}));

const Layout = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [lpsMenuOpen, setLpsMenuOpen] = useState(false);

  const setLPSMenuOpen = () => {
    setLpsMenuOpen(!lpsMenuOpen);
    if (!lpsMenuOpen) {
      dispatch(receiveLPSConfigsRequest());
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={styles.layoutWrapper}>
      <CssBaseline />
      <Header
        drawerOpen={handleDrawerOpen}
        drawerLPSOpen={setLPSMenuOpen}
        lpsMenuOpen={lpsMenuOpen}
        open={open}
      />
      <SideBar
        //@ts-ignore
        drawerClose={handleDrawerClose}
        open={open}
      />
      <Main open={open} lpsMenuOpen={lpsMenuOpen}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};

export default Layout;
