import React from "react";
import { Box, Typography } from "@mui/material";
import { getDateFromNow } from "../../../../common/formats/date-fromatter";
import styles from "../../Leadgen.styles";

const Schedule = ({ schedule }) => {
  // @ts-ignore
  const { label, date, type } = schedule ? getDateFromNow(schedule) : {};
  return (
    (schedule && (
      <Box sx={{ marginLeft: "15px" }}>
        <Typography
          sx={type === "ago" ? styles.colorAgo : styles.colorIn}
          variant="body2"
        >
          {date || ""}
        </Typography>
        <Typography
          sx={type === "ago" ? styles.colorAgo : styles.colorIn}
          variant="body2"
        >
          Overdue {label || ""}
        </Typography>
      </Box>
    )) || (
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            lg: "14px",
            xl: "16px",
          },
          marginLeft: "15px",
        }}
        variant="body2"
      >
        No Due Date
      </Typography>
    )
  );
};

export default Schedule;
