import React, { useEffect, useState } from "react";
import {
  Box,
  Toolbar,
  AppBar,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { selectSuperAdmin } from "./selectors";
import { connect, useDispatch } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import styles from "./SuperAdmin.styles";
import {
  receiveLabelsListRequest,
  receiveTagsListRequest,
  updateTagRequest,
  updateLabelRequest,
  connectToDriveRequest,
  disconnectDriveRequest,
  checkGoogleDriveConnectionRequest,
} from "./actions";
import eventEmitter, {
  types,
} from "../../common/components/helpers/eventEmitter";
import AccessDeniedPage from "../../containers/access-denied-page/AccessDenied";
import { selectUser } from "../login/selectors";
import { Info } from "@mui/icons-material";

const initialEditModeStateTag = {
  isEdit: false,
  id: "",
};

const initialEditModeStateLabel = {
  isEdit: false,
  id: "",
};

const initialTagDetails = {
  id: "",
  name: "",
  is_allowed: false,
  modified: false,
};

const initialLabelDetails = {
  id: "",
  name: "",
  is_allowed: false,
  modified: false,
};

const SuperAdmin = (props) => {
  const { labelsList, tagsList, userRole, driveConnectionCheck } = props;
  const dispatch = useDispatch();
  const [driveConnection, setDriveConnection] = useState(false);
  const [labels, setLabels] = useState(tagsList);
  const [tags, setTags] = useState(labelsList);
  const [editModeTag, setEditModeTag] = useState(initialEditModeStateTag);
  const [editModeLabel, setEditModeLabel] = useState(initialEditModeStateLabel);
  const [editedTagDetails, setEditedTagDetails] = useState(initialTagDetails);
  const [editedLabelDetails, setEditedLabelDetails] =
    useState(initialLabelDetails);

  useEffect(() => {
    dispatch(receiveLabelsListRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(receiveTagsListRequest());
  }, [dispatch]);

  useEffect(() => {
    setLabels(labelsList);
  }, [dispatch, labelsList]);

  useEffect(() => {
    setTags(tagsList);
  }, [dispatch, tagsList]);

  useEffect(() => {
    dispatch(checkGoogleDriveConnectionRequest());
  }, [dispatch]);

  useEffect(() => {
    setDriveConnection(driveConnectionCheck);
  }, [dispatch, driveConnectionCheck]);

  const handleAddConnection = () => {
    dispatch(connectToDriveRequest());
  };

  const handleRemoveConnection = () => {
    dispatch(disconnectDriveRequest());
  };

  const handleSetEditMode = (type, selectedId) => {
    if (type === "openLabelEdit") {
      const labelEdit = labels.filter((item: any) => item.id === selectedId);
      setEditModeLabel({ isEdit: !editModeLabel.isEdit, id: selectedId });
      setEditedLabelDetails({
        ...editedLabelDetails,
        name: labelEdit[0].name,
        id: labelEdit[0].id,
      });
    }

    if (type === "saveLabelEdit") {
      const { id, name } = editedLabelDetails;
      dispatch(updateLabelRequest(id, { name }));
      setEditModeLabel({ isEdit: !editModeLabel.isEdit, id: selectedId });
    }

    if (type === "closeLabelEdit") {
      setEditModeLabel({ isEdit: !editModeLabel.isEdit, id: selectedId });
    }

    if (type === "openTagEdit") {
      const tagEdit = tags.filter((item: any) => item.id === selectedId);
      setEditModeTag({ isEdit: !editModeTag.isEdit, id: selectedId });
      setEditedTagDetails({
        ...editedTagDetails,
        name: tagEdit[0].name,
        id: tagEdit[0].id,
      });
    }

    if (type === "saveTagEdit") {
      const { id, name } = editedTagDetails;
      dispatch(updateTagRequest(id, { name }));
      setEditModeTag({ isEdit: !editModeTag.isEdit, id: selectedId });
    }

    if (type === "closeTagEdit") {
      setEditModeTag({ isEdit: !editModeTag.isEdit, id: selectedId });
    }
  };

  const handleChangeLabel = (fieldName, selectedId, event) => {
    setEditedLabelDetails({
      ...editedLabelDetails,
      name: event.target.value,
      id: selectedId,
      modified: true,
    });
  };

  const handleChangeTag = (fieldName, selectedId, event) => {
    setEditedTagDetails({
      ...editedTagDetails,
      name: event.target.value,
      id: selectedId,
      modified: true,
    });
  };

  return (
    <>
      {userRole !== "Superadmin" ? (
        <AccessDeniedPage />
      ) : (
        <Box>
          <AppBar position="relative" sx={styles.dataBox}>
            <Toolbar sx={styles.toolBar}>
              <Box sx={styles.labelsBox}>
                <Typography component="div" sx={styles.headerContent}>
                  Labels: <b>{labels.length}</b>
                </Typography>
              </Box>
              <Box>
                <Typography component="div" sx={styles.headerContent}>
                  Tags: <b>{tags.length}</b>
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
          <Paper sx={styles.formHolder}>
            <Box sx={styles.container}>
              <Box>
                {driveConnection ? (
                  <Box sx={styles.wrapperGoogleDrive}>
                    <Typography
                      component="div"
                      sx={[styles.headerContent, styles.googleDrive]}
                    >
                      System is connect to Randvast G-Suite.
                      <br /> If you want to connect to another drive, please
                      contact project's owner.
                    </Typography>
                    <Button
                      variant="contained"
                      sx={[styles.buttonAdd, styles.buttonDisconnect]}
                      startIcon={<DeleteIcon />}
                      onClick={handleRemoveConnection}
                    >
                      Disconnect
                    </Button>
                    <Tooltip
                      title={
                        "Clue: note, that connection to google-drive should make only G-suite owners! Please contact project owners before connect to drive."
                      }
                    >
                      <IconButton>
                        <Info sx={{ color: "#495057" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box sx={styles.wrapperGoogleDrive}>
                    <Typography
                      component="div"
                      sx={[styles.headerContent, styles.googleDrive]}
                    >
                      Google drive is not connected yet.
                    </Typography>
                    <Button
                      variant="contained"
                      sx={styles.buttonAdd}
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      onClick={() => handleAddConnection()}
                    >
                      Add connection
                    </Button>
                    <Tooltip
                      title={
                        "Clue: note, that connection to google-drive should make only G-suite owners! Please contact project owners before connect to drive."
                      }
                    >
                      <IconButton>
                        <Info sx={{ color: "#495057" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>

              <Grid
                container
                flexDirection={{ xs: "column", sm: "row" }}
                columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                justifyContent="flex-end"
              >
                <Grid
                  container
                  size="grow"
                  flexDirection={{ xs: "column" }}
                  rowSpacing={2}
                  sx={{ marginBottom: { xs: 4, sm: 0 } }}
                >
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      sx={styles.buttonCreate}
                      onClick={() =>
                        eventEmitter.emit(
                          types.openLabelTagCreateModal,
                          "labelCreate"
                        )
                      }
                    >
                      Create label
                    </Button>
                  </Grid>
                  {/*<Grid*/}
                  {/*  flexGrow={1}*/}
                  {/*>*/}
                  <Paper sx={styles.containerLabels}>
                    <List sx={styles.listContainer}>
                      {Array.isArray(labels) &&
                        labels.map(({ id, name }) => (
                          <ListItem
                            key={id}
                            sx={styles.listItem}
                            secondaryAction={
                              editModeLabel.isEdit &&
                              editModeLabel.id === id ? (
                                <>
                                  <IconButton
                                    edge="end"
                                    aria-label="done"
                                    onClick={() =>
                                      handleSetEditMode("saveLabelEdit", id)
                                    }
                                  >
                                    <DoneIcon sx={styles.iconButton} />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="close"
                                    onClick={() =>
                                      handleSetEditMode("closeLabelEdit", id)
                                    }
                                  >
                                    <CloseIcon sx={styles.iconButton} />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={() =>
                                      handleSetEditMode("openLabelEdit", id)
                                    }
                                  >
                                    <EditIcon
                                      sx={[styles.iconButton, styles.iconList]}
                                    />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                      eventEmitter.emit(
                                        types.openDeleteLabelTagModal,
                                        "labelDelete",
                                        id
                                      )
                                    }
                                  >
                                    <DeleteIcon
                                      sx={[styles.iconButton, styles.iconList]}
                                    />
                                  </IconButton>
                                </>
                              )
                            }
                          >
                            {editModeLabel.isEdit && editModeLabel.id === id ? (
                              <TextField
                                variant="standard"
                                name="name"
                                onChange={(e) =>
                                  handleChangeLabel("name", id, e)
                                }
                                // value={name}
                                defaultValue={name}
                              />
                            ) : (
                              <ListItemText primary={name} />
                            )}
                          </ListItem>
                        ))}
                    </List>
                  </Paper>
                  {/*</Grid>*/}
                </Grid>
                <Grid
                  container
                  size="grow"
                  flexDirection={{ xs: "column" }}
                  rowSpacing={2}
                >
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      sx={styles.buttonCreate}
                      onClick={() =>
                        eventEmitter.emit(
                          types.openLabelTagCreateModal,
                          "tagCreate"
                        )
                      }
                    >
                      Create tag
                    </Button>
                  </Grid>
                  {/*<Grid*/}
                  {/*   flexGrow={1}*/}
                  {/*  // direction="row"*/}
                  {/*>*/}
                  <Paper sx={styles.containerLabels}>
                    <List sx={styles.listContainer}>
                      {Array.isArray(tags) &&
                        tags.map(({ id, name }) => (
                          <ListItem
                            key={id}
                            sx={styles.listItem}
                            secondaryAction={
                              editModeTag.isEdit && editModeTag.id === id ? (
                                <>
                                  <IconButton
                                    edge="end"
                                    aria-label="done"
                                    onClick={() =>
                                      handleSetEditMode("saveTagEdit", id)
                                    }
                                  >
                                    <DoneIcon sx={styles.iconButton} />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="close"
                                    onClick={() =>
                                      handleSetEditMode("closeTagEdit", id)
                                    }
                                  >
                                    <CloseIcon sx={styles.iconButton} />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={() =>
                                      handleSetEditMode("openTagEdit", id)
                                    }
                                  >
                                    <EditIcon
                                      sx={[styles.iconButton, styles.iconList]}
                                    />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                      eventEmitter.emit(
                                        types.openDeleteLabelTagModal,
                                        "tagDelete",
                                        id
                                      )
                                    }
                                  >
                                    <DeleteIcon
                                      sx={[styles.iconButton, styles.iconList]}
                                    />
                                  </IconButton>
                                </>
                              )
                            }
                          >
                            {editModeTag.isEdit && editModeTag.id === id ? (
                              <TextField
                                name="name"
                                variant="standard"
                                onChange={(e) => handleChangeTag("name", id, e)}
                                // value={name}
                                defaultValue={name}
                              />
                            ) : (
                              <ListItemText primary={name} />
                            )}
                          </ListItem>
                        ))}
                    </List>
                  </Paper>
                  {/*</Grid>*/}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  const { labelsList, tagsList, driveConnectionCheck } =
    selectSuperAdmin(state);
  const { userRole } = selectUser(state);
  return { labelsList, tagsList, driveConnectionCheck, userRole };
}

export default connect(mapStateToProps)(SuperAdmin);
