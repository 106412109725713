import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import styles from "../LPSForm.styles";
import React, { useEffect } from "react";

import StyledTextField from "../../../common/components/styled-components/StyledTextField";

const LPSEntityKOABlock = ({
  onChange,
  entityKOAValue,
  isFilledFields,
  changeFilledFields,
}) => {
  useEffect(() => {
    if (entityKOAValue) {
      changeFilledFields({
        ...isFilledFields,
        isFilledFieldConfigEntityKoaPoints: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityKOAValue]);

  return (
    <>
      <AppBar position="relative" sx={styles.dataBoxSecond}>
        <Toolbar sx={styles.toolBarSecond}>
          <Typography component="div" sx={styles.headerContentSecond}>
            Entity kadaster objects amount
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={styles.fieldHolderSecond}>
        <StyledTextField
          fullWidth
          error={
            !!Object.keys(isFilledFields).length &&
            !isFilledFields.isFilledFieldConfigEntityKoaPoints &&
            isFilledFields.isFilledFieldConfigEntityKoaPoints !== undefined
          }
          required
          label="Value"
          sx={styles.inputTextField}
          slotProps={{
            inputLabel: {
              sx: {
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "1rem",
                },
              },
            },
          }}
          margin="dense"
          type="number"
          onChange={(e) =>
            onChange(
              "lps_config_entity_KOA_rules",
              "lps_config_entity_KOA_points",
              e
            )
          }
          value={entityKOAValue}
        />
      </Box>
    </>
  );
};

export default LPSEntityKOABlock;
