import { combineReducers } from "redux";
import {
  RECEIVE_DOCS_GOOGLE_DRIVE_SUCCESS,
  RECEIVE_DOCS_GOOGLE_DRIVE_ERROR,
  SEND_NEW_FILES_GOOGLE_DRIVE_SUCCESS,
  SEND_NEW_FILES_GOOGLE_DRIVE_ERROR,
  ADD_NEW_FILES_GOOGLE_DRIVE_SUCCESS,
  RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST,
} from "../../../common/constants/action-constants";

const initialState = [];

const receiveGoogleDriveFolderData = (
  state = { isFetching: false },
  action
) => {
  switch (action.type) {
    case RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST:
      // case SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_DOCS_GOOGLE_DRIVE_SUCCESS:
      return {
        folderData: action.data,
        isFetching: false,
      };

    default:
      return state;
  }
};

const googleDriveError = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_DOCS_GOOGLE_DRIVE_ERROR:
    case SEND_NEW_FILES_GOOGLE_DRIVE_ERROR:
      return {
        error: action.error,
      };

    default:
      return state;
  }
};

const importFilesGoogleDriveFolder = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_FILES_GOOGLE_DRIVE_SUCCESS:
    case SEND_NEW_FILES_GOOGLE_DRIVE_SUCCESS:
      return {
        files: action.data,
      };
    default:
      return state;
  }
};

const driveFolder = combineReducers({
  receiveGoogleDriveFolderData,
  importFilesGoogleDriveFolder,
  error: googleDriveError,
});
export default driveFolder;
