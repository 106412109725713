const styles = {
  layoutWrapper: {
    display: "flex",
    minHeight: "100vh",
    height: "100%",
    backgroundColor: "#F8F8FB",
  },
  main: {
    width: "100%",
  },
};

export default styles;
