import React, { ChangeEvent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ObjectEditor.styles";
import ObjectGeneralFieldsBlock from "./object-editor-blocks/ObjectGeneralFieldsBlock";
import ObjectPlotBlock from "./object-editor-blocks/ObjectPlotBlock";
import ObjectAddressBlock from "./object-editor-blocks/ObjectAddressBlock";
import ObjectUnitsBlock from "./object-editor-blocks/ObjectUnitsBlock";
import { kadIdValidator, objectFormValidator } from "../helpers/validator";
import AlertService from "../../services/notification.service";
import { createNewObjectRequest } from "../../../modules/add-new-entity/actions";
import { connect, useDispatch } from "react-redux";
import { selectLeadgen } from "../../../modules/leadgen/selectors";
import { updateObjectRequest } from "../../../modules/leadgen/actions/objects";
import DefaultButton from "../button/Button";

const initialErrorsState = {
  isFilledKadId: true,
  isFilledCityCode: true,
  isFilledPlotNumber: true,
  isFilledSection: true,
  isFilledPlotIndex: true,
  isFilledCity: true,
};
const ObjectEditor = (props) => {
  // @ts-ignore
  const { typeEdit, currentObject, objectId } = props;
  const { plot } = currentObject;
  const initialObjectState = {
    general_fields: {
      kadaster_id: "",
      kadaster_url: "",
      price: "",
      restrictions: "",
    },
    plot: {
      city_code: "",
      section: "",
      plot_number: "",
      index: "",
    },
    address: {
      city_id: "",
      city_name: "",
      street_name: "",
      number: "",
      number_add: "",
      postcode: "",
    },
    units: [],
    unitsForDelete: [],
  };
  const [objectForSave, setObjectForSave] = useState(initialObjectState);
  const [errors, setErrors] = useState(initialErrorsState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [actionTypes, setActionTypes] = useState<Array<string>>([]);

  useEffect(() => {
    if (typeEdit === "objectEdit" && Object.keys(currentObject).length > 0) {
      setObjectForSave({
        ...objectForSave,
        general_fields: {
          ...objectForSave.general_fields,
          kadaster_id: currentObject.kadaster_id || "",
          kadaster_url: currentObject.kadaster_url || "",
          price: currentObject.price || "",
          restrictions: currentObject.restrictions || "",
        },
        plot: {
          ...objectForSave.plot,
          city_code: plot.city_code || "",
          section: plot.section || "",
          plot_number: plot.plot_number || "",
          index: plot.index || "",
        },
        address: {
          ...objectForSave.address,
          city_id: currentObject.object_city.city_id,
          city_name: currentObject.object_city.city_name,
          street_name: currentObject.objAddress.street_name || "",
          number: currentObject.objAddress.house_number || "",
          number_add: currentObject.objAddress.number_add || "",
          postcode: currentObject.objAddress.postcode || "",
        },
        units: currentObject.units || [],
      });
      // setObjectId(currentObject.object_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeEdit, currentObject, plot]);

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (name === "generalFields") {
      if (objectForSave.general_fields.kadaster_id) {
        setErrors({ ...errors, isFilledKadId: true });
      }
      if (!actionTypes.includes("general_fields")) {
        setActionTypes([...actionTypes, "general_fields"]);
      }
      return setObjectForSave({
        ...objectForSave,
        general_fields: {
          ...objectForSave.general_fields,
          [key]: event.target.value,
        },
      });
    }
    if (name === "objectPlot") {
      if (objectForSave.plot.city_code) {
        setErrors({ ...errors, isFilledCityCode: true });
      }
      if (objectForSave.plot.plot_number) {
        setErrors({ ...errors, isFilledPlotNumber: true });
      }
      if (objectForSave.plot.index) {
        setErrors({ ...errors, isFilledPlotIndex: true });
      }
      if (objectForSave.plot.section) {
        setErrors({ ...errors, isFilledSection: true });
      }
      if (!actionTypes.includes("plot")) {
        setActionTypes([...actionTypes, "plot"]);
      }

      return setObjectForSave({
        ...objectForSave,
        plot: {
          ...objectForSave.plot,
          [key]: event.target.value,
        },
      });
    }
    if (name === "objectAddress") {
      if (objectForSave.address.city_name !== "") {
        setErrors({ ...errors, isFilledCity: true });
      }
      if (!actionTypes.includes("address")) {
        setActionTypes([...actionTypes, "address"]);
      }

      return setObjectForSave({
        ...objectForSave,
        address: {
          ...objectForSave.address,
          [key]: event.target.value,
        },
      });
    }
  };

  const updateUnits = (types, item, editMode) => {
    setActionTypes([...actionTypes, ...types]);
    if (
      item.newStateForDelete &&
      item.newStateForDelete.length &&
      !actionTypes.includes("units")
    ) {
      setActionTypes([...actionTypes, "units"]);
    }
    if (editMode) {
      return setObjectForSave({
        ...objectForSave,
        // @ts-ignore
        units: [...item.filteredList],
        // @ts-ignore
        unitsForDelete: [...item.newStateForDelete],
      });
    }
    return setObjectForSave({
      ...objectForSave,
      // @ts-ignore
      units: [...item],
    });
  };

  const handleSave = async () => {
    let isExistedKadId;
    if (objectForSave.general_fields.kadaster_id) {
      isExistedKadId = await kadIdValidator("object", objectForSave);

      if (isExistedKadId) {
        return AlertService.warning("Object with this KAD ID already exits");
      }
    }

    const isValidObject = objectFormValidator(objectForSave);
    setErrors(isValidObject);
    if (
      Object.keys(isValidObject).every((item) => isValidObject[item] === true)
    ) {
      if (typeEdit !== "objectEdit") {
        dispatch(createNewObjectRequest(objectForSave, navigate));
      }
      if (typeEdit === "objectEdit") {
        // @ts-ignore
        const uniqueTypes = [...new Set(actionTypes)];
        dispatch(
          updateObjectRequest(uniqueTypes, objectId, objectForSave, null)
        );
        // console.log('Object edit updated successfully');
        props.closeModal();
      }
    } else {
      AlertService.error("Fields are not valid");
    }
  };

  const handleCancel = () => {
    setErrors(initialErrorsState);
    setObjectForSave({
      general_fields: {
        kadaster_id: "",
        kadaster_url: "",
        price: "",
        restrictions: "",
      },
      plot: {
        city_code: "",
        section: "",
        plot_number: "",
        index: "",
      },
      address: {
        city_id: "",
        city_name: "",
        street_name: "",
        number: "",
        number_add: "",
        postcode: "",
      },
      units: [],
      unitsForDelete: [],
    });
  };

  return (
    <Box sx={styles.root}>
      <ObjectGeneralFieldsBlock
        onChange={handleChange}
        objectFields={objectForSave.general_fields}
        errors={errors}
      />
      <ObjectPlotBlock
        onChange={handleChange}
        objectFields={objectForSave.plot}
        errors={errors}
      />
      <ObjectAddressBlock
        onChange={handleChange}
        objectFields={objectForSave}
        // objectFields={objectForSave.address}
        unitsLength={objectForSave.units.length}
        errors={errors}
        selectAddressObject={setObjectForSave}
      />
      <ObjectUnitsBlock onChange={updateUnits} units={objectForSave.units} />
      <Box sx={styles.buttonHolder}>
        <DefaultButton
          variant="contained"
          styleType="save"
          handleClick={handleSave}
        >
          Save
        </DefaultButton>
        <DefaultButton
          variant="contained"
          styleType="cancel"
          handleClick={
            typeEdit === "objectEdit" ? props.closeModal : handleCancel
          }
        >
          Cancel
        </DefaultButton>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  const { currentObject } = selectLeadgen(state);
  return { currentObject };
}

export default connect(mapStateToProps)(ObjectEditor);
