import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import {
  receiveDependentEntitiesRequest,
  updateEntityRequest,
} from "../../../../../modules/leadgen/actions/entityDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import EntitySearch from "../../../../../modules/search/components/EntitySearch";
import AlertService from "../../../../services/notification.service";

const EntityContactsModal = (props: any) => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState("");
  const [entityContactsForSave, setEntityContacts] = useState([] as any);
  const [entityContactsForDelete, setEntityContactsForDelete] = useState(
    [] as any
  );
  const [contacts, setContactsList] = useState([] as any);
  const [deleteMode, setDeleteMode] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const actionTypes = ["contacts"];
  const { currentContacts } = props;

  const getContactName = (contact: any) => {
    if (contact.name) {
      return contact.name;
    } else if (contact.last_name && contact.first_name) {
      return `${contact.first_name} ${contact.last_name}`;
    } else return contact.last_name;
  };

  const handleOpen = (entityId: string) => {
    setEntityId(entityId);
    dispatch(receiveDependentEntitiesRequest(entityId));
  };

  const handleDeleteAllContacts = () => {
    setDeleteMode(true);
    const contactsIds = contacts.map((contact: any) => {
      return contact.id;
    });
    setEntityContactsForDelete(contactsIds);
    setContactsList([]);
  };

  const handleDeleteSelectedContact = (contact: any) => {
    setDeleteMode(true);
    const contactsForDelete: any = [];
    const contactsForLeave: any = [];
    // eslint-disable-next-line array-callback-return
    contacts.map((currentContact: any) => {
      if (currentContact.id === contact.id) {
        contactsForDelete.push(currentContact.id);
      } else {
        contactsForLeave.push(currentContact);
      }
    });

    setEntityContactsForDelete(contactsForDelete);
    setContactsList(contactsForLeave);
  };

  const handleChange = (contact: any) => {
    const updatedContactsList: any = [...contacts];
    const updatedContactsListForSave: any = [];
    if (
      updatedContactsList.some(
        (currentContact: any) => currentContact.id === contact.id
      )
    ) {
      return AlertService.warning("This contact is already added to entity");
    } else {
      updatedContactsList.push(contact);
      updatedContactsListForSave.push(contact.id);
    }

    setContactsList(updatedContactsList);
    setEntityContacts(updatedContactsListForSave);
  };

  useEffect(() => {
    currentContacts &&
      currentContacts.length &&
      setContactsList(currentContacts);
  }, [dispatch, currentContacts]);

  const handleSave = () => {
    dispatch(
      updateEntityRequest(deleteMode, actionTypes, currentEntityId, {
        entityContactsForSave,
        entityContactsForDelete,
      })
    );
    setDeleteMode(false);
    setEntityContacts([]);
    setEntityContactsForDelete([]);
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openEntityContactsModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title="Set entity contacts" />
            <Container>
              <List sx={styles.list}>
                {contacts && contacts.length > 0 ? (
                  contacts.map((contact: any) => (
                    <ListItem key={contact.id}>
                      <ListItemText>{getContactName(contact)}</ListItemText>
                      <IconButton>
                        <DeleteIcon
                          sx={styles.deleteIcon}
                          onClick={() => handleDeleteSelectedContact(contact)}
                        />
                      </IconButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" sx={styles.emptyList}>
                    There is no contacts yet
                  </Typography>
                )}
              </List>
              <Button
                sx={styles.buttonContent}
                onClick={() => {
                  handleDeleteAllContacts();
                }}
              >
                Set empty entity contacts
              </Button>
              <Button
                sx={styles.buttonContent}
                onClick={() => setOpenSearch(!openSearch)}
              >
                Search entity contacts
              </Button>
              {openSearch && (
                <>
                  <Divider />
                  <EntitySearch handleChangeList={handleChange} />
                </>
              )}
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={handleSave}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state) {
  const { currentContacts } = selectLeadgen(state);
  return { currentContacts };
}
export default connect(mapStateToProps)(EntityContactsModal);
