import UserLogsModal from "./user-logs-modal/UserLogsModal";
import UserModal from "./user-modal/UserModal";
import UserDeleteModal from "./user-delete-modal/UserDeleteModal";
import GlobalSearchModal from "../../../../modules/search/GlobalSearch";
import ObjectSearchModal from "../../../../modules/search/components/ObjectSearch";
import ScheduleCalendarModal from "./schedule-calendar/ScheduleCalendar";
import EntityLabelModal from "./entity-label/EntityLabelModal";
import EntityTagsModal from "./entity-tag/EntityTagsModal";
import EntityResponsibleUserModal from "./entity-responsible-user/EntityResponsibleUserModal";
import EntityContactsModal from "./entity-contacts/EntityContactsModal";
import EntityManagersModal from "./entity-managers/EntityManagersModal";
import ObjectsUnlinkModal from "./unlink-objects/UnlinkObjectsModal";
import PDFUnitsDetailsModal from "./pdf-modal/PDFUnitsDetailsModal";
import LeadsNotesModal from "./lead-notes/LeadsNotesModal";
import LabelTagCreateModal from "./label-tag-create-modal/LabelTagCreateModal";
import LabelTagDeleteModal from "./label-tag-delete-modal/LabelTagDeleteModal";
import EntityObjectDeleteModal from "./entity-object-delete-modal/EntityObjectDeleteModal";
import EntityObjectEditModal from "./entity-object-edit-modal/EntityObjectEditModal";

export default function Modals() {
  return (
    <>
      <UserModal />
      <UserLogsModal />
      <UserDeleteModal />
      <GlobalSearchModal />
      <ScheduleCalendarModal />
      <EntityLabelModal />
      <EntityTagsModal />
      <EntityResponsibleUserModal />
      <EntityContactsModal />
      <EntityManagersModal />
      <ObjectSearchModal />
      <ObjectsUnlinkModal />
      <PDFUnitsDetailsModal />
      <LeadsNotesModal />
      <LabelTagDeleteModal />
      <LabelTagCreateModal />
      <EntityObjectDeleteModal />
      <EntityObjectEditModal />
    </>
  );
}
