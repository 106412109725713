import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "./PostcodeForm.styles";
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import StyledTextField from "../styled-components/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import InputHelperText from "../styled-components/InputHelperText";
import {
  receivePostcodeRequest,
  resetPostcode,
} from "../../../modules/add-new-entity/actions";
import { createEntity } from "../../../modules/add-new-entity/selectors";

type PostcodeFormProps = {
  addressPostcode?: any;
  selectAddress?: (...args) => any;
  address?: {
    city_id: number | string;
    city: string;
    postcode: string;
    street_name: string;
    number: string;
    number_add: number;
    address: any;
  };
  type?: string;
};

type ValueType = {
  city: string;
  postcode: string;
  number: string;
  street: string;
  letter: string;
  addition: string;
};

type DefaultValueType = null;

const PostcodeForm = ({
  addressPostcode,
  selectAddress,
  address,
  type,
}: PostcodeFormProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<ValueType | DefaultValueType>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    let active = true;
    let newOptions: any = [];

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (active) {
      if (value) {
        newOptions = [value];
      }
      if (addressPostcode?.length) {
        newOptions = [...newOptions, ...addressPostcode];
      }
      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, value, addressPostcode, inputValue]);

  const handleChange = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 6 && newInputValue.length <= 12) {
      const requestData = {
        inputValue: newInputValue,
      };
      dispatch(resetPostcode());
      if (value === null) {
        dispatch(receivePostcodeRequest(requestData));
      }
    }
  };

  useEffect(() => {
    if (value && address && type === "entity") {
      selectAddress &&
        selectAddress({
          ...address,
          city: value.city,
          postcode: value.postcode,
          street_name: value.street,
          number: value.number,
          number_add: `${value.letter ? value.letter : ""}${
            value.addition ? value.addition : ""
          }`,
        });

      return;
    }
    if (value && address && type === "object") {
      selectAddress &&
        selectAddress({
          ...address,
          address: {
            ...address.address,
            city_id: address.city_id || "",
            city_name: value.city || "",
            street_name: value.street || "",
            number: value.number || "",
            number_add:
              `${value.letter ? value.letter : ""}${
                value.addition ? value.addition : ""
              }` || "",
            postcode: value.postcode || "",
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <Box sx={styles.fieldBlock} component="form">
        <FormControl sx={styles.form} error={!!(inputValue && !value)}>
          <Autocomplete
            id="postcode"
            open={open && inputValue.length >= 6}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            fullWidth
            freeSolo
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(newValue: {}) => {
              // @ts-ignore
              setValue(newValue);
            }}
            clearOnEscape={true}
            onInputChange={(e, newInputValue) => handleChange(newInputValue)}
            options={options}
            getOptionLabel={(option) =>
              `${option.postcode ? option.postcode : ""} ${
                option.city ? option.city : ""
              }, ${option.street ? option.street : ""} ${
                option.number ? option.number : ""
              } ${option.letter ? option.letter : ""} ${
                option.addition ? option.addition : ""
              }`
            }
            renderInput={(params) => (
              <StyledTextField
                {...params}
                variant="standard"
                margin="dense"
                error={!!(inputValue && !value)}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <InputHelperText
            style={{ color: "red" }}
            text="Type postcode and number to search an address"
          />
        </FormControl>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  const { addressPostcode } = createEntity(state);
  return { addressPostcode };
}

export default connect(mapStateToProps)(PostcodeForm);
