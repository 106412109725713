import * as React from "react";
import { Button } from "@mui/material";
import styles from "./Button.styles";
import { buttonTypes } from "../../constants/field-constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DefaultButton = ({
  children,
  styleType,
  variant,
  handleClick,
  ...attrs
}) => {
  const tag = attrs.href ? "a" : "button";

  return (
    <>
      {styleType === buttonTypes.SAVE && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={styles.buttonSave}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {(styleType === buttonTypes.CLOSE ||
        styleType === buttonTypes.CANCEL) && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={styles.buttonCancel}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {(styleType === buttonTypes.DELETE ||
        styleType === buttonTypes.UNLINK) && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={styles.buttonDelete}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {styleType === buttonTypes.EDIT && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={[styles.buttonSave, styles.buttonEdit]}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {styleType === buttonTypes.DELETELEADGEN && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={[styles.buttonDelete, styles.buttonDeleteLeadgen]}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {styleType === buttonTypes.CANCELLEADGEN && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={[styles.buttonCancel, styles.buttonCancelLeadgen]}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {styleType === buttonTypes.EDITOBJECTS && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={styles.buttonEditObjects}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}

      {styleType === buttonTypes.DELETEOBJECTS && (
        <Button
          {...attrs}
          component={tag}
          variant={variant}
          sx={styles.buttonDeleteObjects}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default DefaultButton;
