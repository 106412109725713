import { createTheme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    button: {
      save: string;
      saveHover: string;
      saveHoverBorderColor: string;
      cancel: string;
      remove: string;
      removeHover: string;
      removeHoverBorderColor: string;
      cancelHover: string;
      cancelHoverBorderColor: string;
      textColor: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    button?: {
      save: string;
      saveHover: string;
      saveHoverBorderColor: string;
      cancel: string;
      remove: string;
      removeHover: string;
      removeHoverBorderColor: string;
      cancelHover: string;
      cancelHoverBorderColor: string;
      textColor: string;
    };
  }
  // interface BreakpointOverrides {
  //   xs: false; // removes the `xs` breakpoint
  //   sm: false;
  //   md: false;
  //   lg: false;
  //   xl: false;
  //   mobile: true; // adds the `mobile` breakpoint
  //   tablet: true;
  //   laptop: true;
  //   desktop: true;
  // }
}

const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#114B76",
    },
    secondary: {
      main: "#015A9C",
      light: "#B4CEE5",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    // fontWeight: {
    //   main: 400,
    //   light: 500
    //   bold: 600
    // }
  },
  direction: "rtl",
  button: {
    save: "rgba(4, 98, 54, 0.8)",
    saveHover: "rgba(4, 98, 54, 0.9)",
    saveHoverBorderColor: "#046236",
    remove: "#D67575",
    removeHover: "#d01f1f",
    removeHoverBorderColor: "#e53a3a",
    cancel: "#658EB1",
    cancelHover: "rgba(17,75,118,0.9)",
    cancelHoverBorderColor: "#114B76",
    textColor: "#FFFFFF",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;

// #114B76
// "#015A9C"
// "#B4CEE5"
// "#F8F8FB"
// "#F59292FF"

/*header {*/
/*  background-color: gray;*/
/*  padding: 2rem 0;*/
/*  text-align: center;*/
/*}*/
/*li {*/
/*  padding: 1rem 0;*/
/*}*/
/*header > a {*/
/*  color: white;*/
/*  text-decoration: none;*/
/*  padding: 0 1rem;*/
/*}*/
/*header > a:hover {*/
/*  text-decoration: underline;*/
/*}*/

/*.App {*/
/*  text-align: center;*/
/*  backgroundcolor: "#F8F8FB";*/
/*}*/

/*.App-logo {*/
/*  margin-top: 80px;*/
/*  height: 80vmin;*/
/*  pointer-events: none;*/
/*  border: 1px solid gray;*/
/*}*/

/*!* @media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*} *!*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*!* @keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*} *!*/
