import React, { useEffect, useState } from "react";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import { Container } from "@mui/material";
import styles from "../Modals.styles";
import { connect, useDispatch } from "react-redux";
import EntityEditor from "../../../entity-editor/EntityEditor";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import ObjectEditor from "../../../object-editor/ObjectEditor";
import { receiveObjectByIdRequest } from "../../../../../modules/leadgen/actions/objects";

const EntityObjectEditModal = (props: any) => {
  const dispatch = useDispatch();
  const { entityMainDetails } = props;
  const [entityDetails, setEntityDetails] = useState({} as any);
  const [editId, setEditId] = useState(null);
  const [typeEdit, setTypeEdit] = useState("");

  useEffect(() => {
    entityMainDetails && setEntityDetails(entityMainDetails);
  }, [entityMainDetails]);

  const handleOpen = (type, id) => {
    setTypeEdit(type);
    setEditId(id);
  };

  useEffect(() => {
    if (editId) {
      dispatch(receiveObjectByIdRequest(editId));
    }
  }, [dispatch, editId]);

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        // onClose={handleClearForm}
        nameEvent={types.openEntityObjectEditModal}
        modalType="search"
      >
        {({ closeModal }) => (
          <>
            <ModalHeader
              title={typeEdit === "entityEdit" ? "EDIT ENTITY" : "EDIT OBJECT"}
            />

            <Container sx={styles.contentBodyEdit}>
              {typeEdit === "entityEdit" ? (
                <EntityEditor
                  //@ts-ignore
                  closeModal={closeModal}
                  type={entityDetails.entity_type}
                  typeEdit={typeEdit}
                  entityId={editId}
                />
              ) : (
                <ObjectEditor
                  objectId={editId}
                  closeModal={closeModal}
                  typeEdit={typeEdit}
                />
              )}
            </Container>
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const entityMainDetails = selectLeadgen(state);
  return entityMainDetails;
}

export default connect(mapStateToProps)(EntityObjectEditModal);
